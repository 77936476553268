import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

import {
  Page,
  ContentContainer,
  MainHeader,
  Main,
  Footer,
} from '../../ui/layout';
import colors from '../../../constants/colors';

const TermsConditions = () => {
  const [rawHtml, setRawHtml] = useState();

  useEffect(() => {
    async function getRawHtml() {
      setRawHtml(
        await axios.get(
          'https://nozl-assets.s3-us-west-2.amazonaws.com/nozl_nozl-terms-of-use.htm'
        )
      );
    }
    getRawHtml();
  }, []);

  return (
    <Page>
      <MainHeader />
      <Main bgColor={colors.iceBlue} flexDirection="column">
        <ContentContainer
          maxWidth="1200px"
          margin="32px auto"
          flex="1 0 auto"
          flexDirection="column"
          padding="32px"
        >
          {rawHtml && ReactHtmlParser(rawHtml.data)}
        </ContentContainer>
      </Main>
      <Footer />
    </Page>
  );
};

export default TermsConditions;
