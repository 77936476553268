import React, { Component } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import { FancyPage, ContentContainer } from '../../ui/layout';
import { Form, TextInput, TextArea } from '../../ui/forms';
import { Title, Subtitle } from '../../ui/text';
import { GradientButton } from '../../ui/buttons';
import ProfileSchema from '../../../utils/forms/schema.profile';

const InputContainer = styled.div`
  display: flex;
  max-width: 1000px;
  flex: 1 0 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

class CreateProfile extends Component {
  handleSubmit = values => {
    const user = {
      airport: {
        name: values.airport,
        icao: values.icao,
        iata: '',
      },
      fbo: {
        name: values.name,
        address: values.address,
        city: values.city,
        managerName: values.managerName,
        contactName: values.contactName,
        email: values.email,
        mobileNumber: values.mobileNumber,
        landline: values.landline,
        area: values.area,
        notes: values.notes,
        uid: this.props.auth.uid,
      },
      services: {
        fuelPricing: {
          tier1: {
            rate: 0,
            limitLow: 0,
            limitHigh: 499,
          },
          tier2: {
            rate: 0,
            limitLow: 500,
            limitHigh: 999,
          },
          tier3: {
            rate: 0,
            limit: 1000,
          },
        },
        commonPricing: {
          hangarFee: {
            name: 'Hangar Fee',
            largeAircraft: 0,
            mediumAircraft: 0,
            smallAircraft: 0,
          },
          rampFee: {
            name: 'Ramp Fee',
            largeAircraft: 0,
            mediumAircraft: 0,
            smallAircraft: 0,
          },
          galToWaiveHandlingFee: {
            name: 'Gal to Waive Handling Fee',
            largeAircraft: 0,
            mediumAircraft: 0,
            smallAircraft: 0,
          },
          handlingFee: {
            name: 'Handling Fee',
            largeAircraft: 0,
            mediumAircraft: 0,
            smallAircraft: 0,
          },
          deIcingPerGallon: {
            name: 'De-Icing Per Gallon',
            largeAircraft: 0,
            mediumAircraft: 0,
            smallAircraft: 0,
          },
        },
      },
    };

    this.props.updateUser(user);
    this.props.push('/dashboard');
  };

  render() {
    return (
      <FancyPage>
        <ContentContainer
          maxWidth="1200px"
          flexDirection="column"
          alignItems="center"
          borderRadius="9px 9px 0 0"
        >
          <div
            style={{
              maxWidth: '100%',
              flex: 1,
              margin: 'auto',
            }}
          >
            <div
              style={{
                maxWidth: '100%',
                flex: 1,
              }}
            >
              <Title style={{ paddingLeft: '20px', paddingTop: '60px' }}>
                Create Your FBO Profile
              </Title>
            </div>
            <Formik
              initialValues={{
                airport: '',
                name: '',
                icao: '',
                address: '',
                area: '',
                city: '',
                contactName: '',
                mobileNumber: '',
                landline: '',
                email: '',
                managerName: '',
                notes: '',
              }}
              isInitialValid={false}
              validationSchema={ProfileSchema}
              onSubmit={values => this.handleSubmit(values)}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldTouched,
                isValid,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {console.log('values', values)}
                  {console.log('errors', errors)}
                  <InputContainer>
                    <div
                      style={{
                        width: '100%',
                        padding: '20px',
                      }}
                    >
                      <Subtitle>FBO Details</Subtitle>
                    </div>
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('airport')}
                      type="text"
                      name="airport"
                      value={values.airport}
                      error={touched.airport && errors.airport}
                      label="Airport that FBO resides in"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('name')}
                      type="text"
                      name="name"
                      value={values.name}
                      error={touched.name && errors.name}
                      label="FBO name"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('icao')}
                      type="text"
                      name="icao"
                      value={values.icao}
                      error={touched.icao && errors.icao}
                      label="ICAO Code"
                    />
                  </InputContainer>

                  <InputContainer>
                    <div
                      style={{
                        width: '100%',
                        padding: '20px',
                      }}
                    >
                      <Subtitle>Location</Subtitle>
                    </div>
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('address')}
                      type="text"
                      name="address"
                      value={values.address}
                      error={touched.address && errors.address}
                      label="Physical address"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('city')}
                      type="text"
                      name="city"
                      value={values.city}
                      error={touched.city && errors.city}
                      label="City"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('area')}
                      type="text"
                      name="area"
                      value={values.area}
                      error={touched.area && errors.area}
                      label="Metropolitan area"
                    />
                  </InputContainer>

                  <InputContainer>
                    <div
                      style={{
                        width: '100%',
                        padding: '20px',
                      }}
                    >
                      <Subtitle>Contact</Subtitle>
                    </div>
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('contactName')}
                      type="text"
                      name="contactName"
                      value={values.contactName}
                      error={touched.contactName && errors.contactName}
                      label="Contact name"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('managerName')}
                      type="text"
                      name="managerName"
                      value={values.managerName}
                      error={touched.managerName && errors.managerName}
                      label="General manager name"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('mobileNumber')}
                      type="text"
                      name="mobileNumber"
                      value={values.mobileNumber}
                      error={touched.mobileNumber && errors.mobileNumber}
                      label="Mobile number"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('landline')}
                      type="text"
                      name="landline"
                      value={values.landline}
                      error={touched.landline && errors.landline}
                      label="Landline phone number"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('email')}
                      type="text"
                      name="email"
                      value={values.email}
                      error={touched.email && errors.email}
                      label="Contact email"
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextArea
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('notes')}
                      type="textarea"
                      name="notes"
                      value={values.notes}
                      error={touched.notes && errors.notes}
                      label="Notes"
                      height="100px"
                    />
                  </InputContainer>

                  <div
                    style={{
                      padding: '20px',
                      margin: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    <GradientButton
                      bold
                      height="50px"
                      width="455px"
                      type="submit"
                      disabled={!isValid}
                    >
                      Submit Profile
                    </GradientButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ContentContainer>
      </FancyPage>
    );
  }
}

export default CreateProfile;
