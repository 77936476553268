import { createAction } from '../utils';
import * as actionTypes from './auth.actionTypes';

export const login = ({ email, password }) =>
  createAction(actionTypes.LOGIN_WITH_EMAIL.REQUEST, { email, password });

export const signUp = ({ email, password }) =>
  createAction(actionTypes.SIGN_UP_WITH_EMAIL.REQUEST, { email, password });

export const logout = () => createAction(actionTypes.LOG_OUT.REQUEST, {});

export const forgotPassword = email =>
  createAction(actionTypes.FORGOT_PASSWORD.REQUEST, { email });

export const onAuthStateChanged = () =>
  createAction(actionTypes.ON_AUTH_STATE_CHANGED.REQUEST, {});
