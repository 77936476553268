import createAction from './createAction';
import createAsyncActionTypes from './createAsyncActionTypes';

export default function createAsyncAction(actionType) {
  const asyncActionType = createAsyncActionTypes(actionType);
  return {
    success: response => createAction(asyncActionType.SUCCESS, response),
    failure: err => createAction(asyncActionType.FAILURE, err),
  };
}
