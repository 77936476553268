import firebase from 'firebase';

// import { firebaseApp } from '../../config/firebase';
import CollectionManager from './collection';

const Users = new CollectionManager('fbos');

export const testNestedCreate = () => {
  return Users.setNestedDoc('7727j2KlOOWksm2p9xAXLQfGQcO2', 'services', {
    test: 'this is a test',
  });
};

/**
 * Sign up with firebase with email and password credential
 */
export const signUpWithEmailAndPassword = ({ email, password }) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(res => JSON.stringify(res))
    .then(res => JSON.parse(res))
    .then(res => {
      if (res.user) {
        const data = {
          auth: {
            uid: res.user.uid,
            email: res.user.email,
          },
          isProfileComplete: false,
          isApproved: false,
        };

        Users.setDoc(res.user.uid, data);

        return {
          ...res.user,
          token: firebase.auth().currentUser.refreshToken,
        };
      }
    })
    .catch(err => {
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/email-already-in-use':
        case 'auth/operation-not-allowed':
        case 'auth/weak-password':
        default:
          throw err;
      }
    });
};
