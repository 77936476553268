import * as actionTypes from './auth.actionTypes';

const initialState = {
  status: 'pending',
  token: null,
  uid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_WITH_EMAIL.REQUEST:
    case actionTypes.SIGN_UP_WITH_EMAIL.REQUEST:
    case actionTypes.LOG_OUT.REQUEST:
      return { ...state, status: 'loading' };

    case actionTypes.LOGIN_WITH_EMAIL.SUCCESS:
    case actionTypes.SIGN_UP_WITH_EMAIL.SUCCESS:
      return {
        status: 'success',
        token: action.payload.token,
        uid: action.payload.uid,
      };

    case actionTypes.LOGIN_WITH_EMAIL.FAILURE:
    case actionTypes.SIGN_UP_WITH_EMAIL.FAILURE:
      return { ...state, status: 'failure' };

    case actionTypes.ON_AUTH_STATE_CHANGED.REQUEST:
      return {
        ...state,
        status: 'pending',
      };

    case actionTypes.ON_AUTH_STATE_CHANGED.SUCCESS:
      return {
        ...state,
        status: 'success',
        token: action.payload.refreshToken,
        uid: action.payload.uid,
      };

    case actionTypes.ON_AUTH_STATE_CHANGED.FAILURE:
    case actionTypes.LOG_OUT.SUCCESS:
      return {
        ...initialState,
        status: 'failed',
      };

    case actionTypes.LOG_OUT.FAILURE:
    default:
      return state;
  }
};
