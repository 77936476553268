import * as actionTypes from './user.actionTypes';
import { LOG_OUT } from '../auth/auth.actionTypes';

const initialState = {
  auth: {
    email: null,
    uid: null,
  },
  fbo: {
    name: '',
  },
  status: 'pending',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER.SUCCESS:
    case actionTypes.UPDATE_USER.SUCCESS:
      return { ...state, ...action.payload, status: 'success' };

    case actionTypes.GET_USER.FAILURE:
    case actionTypes.UPDATE_USER.FAILURE:
      return { ...state, status: 'failed' };

    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
