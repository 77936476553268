import styled, { css } from 'styled-components';

export default styled.button`
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width};
  border-radius: 9px;
  background-size: 50%;
  border: 0px;
  background: linear-gradient(270deg, #3f98ff, #47d7e7);
  box-shadow: 0 4px 10px 0 rgba(66, 178, 244, 0.3);

  color: #fff;
  font-size: 16px;
  letter-spacing: NaNpx;
  line-height: 19px;
  text-align: center;

  ${props =>
    props.bold &&
    css`
      color: #fff;
      font-family: 'Helvetica Neue';
      font-size: 16px;
      font-weight: 700;
      letter-spacing: NaNpx;
      line-height: 19px;
      text-align: center;
    `}
`;
