import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: ${props => props.width || '100%'};
  margin: ${props => (props.margin ? props.margin : '0 auto')};
  margin-top: 32px;
  padding: 0 32px;
`;

const ContentBox = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'stretch')};
  background-color: ${props =>
    props.background ? props.background : '#ffffff'};
  padding: ${props => (props.padding ? props.padding : '12px')};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '9px'};
  box-shadow: 0 8px 14px 0 rgba(0, 34, 87, 0.08);
  background-color: #ffffff;
  width: 100%;
  max-width: ${props => props.maxWidth || '800px'};
`;

const ContentContainer = props => {
    return  <Wrapper>
        <ContentBox {...props}>
            {props.children}
        </ContentBox>
    </Wrapper>
};

export default ContentContainer;
