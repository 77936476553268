import styled, { css } from 'styled-components';

export default styled.button`
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width};
  color: ${props => props.color && props.color};
  font-family: 'Helvetica Neue';
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.color && props.color};
  ${'' /* border-image-source: linear-gradient(83deg, #50d8f2, #38aff6); */}
  ${'' /* border-image-slice: 1; */}
  ${
    '' /* background-image: #f8fdfe, linear-gradient(83deg, #50d8f2, #38aff6); */
  }
  ${'' /* background-origin: border-box; */}
  ${'' /* background-clip: content-box, border-box; */}
  border-radius: 9px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;
