import React from 'react';
import { Link } from 'react-router-dom';

import colors from '../../../../constants/colors';
import { ContentContainer } from '../../../ui/layout';
import { Cell, CellHeader, Heading, DataTable } from '../../../ui/tables';
import { HighlightedText } from '../../../ui/text';
import { GradientButton } from '../../../ui/buttons';

const PendingRequests = ({ requests, services }) => {
  const requestData = requests => {
    if (requests && requests.length !== 0) {
      return requests
        .filter(request => request.status === 'pending')
        .map(request => {
        const { fuelRequestInfo, user, status, uid } = request;

        const calcRate = volume => {
          const { fuelPricing } = services;
          if (volume <= fuelPricing.tier1.limitHigh) {
            return fuelPricing.tier1.rate;
          } else if (volume <= fuelPricing.tier2.limitHigh) {
            return fuelPricing.tier2.rate;
          } else {
            return fuelPricing.tier3.rate;
          }
        };

        const data = {
          registry: fuelRequestInfo.registry,
          operator: user.operator,
          name: user.name,
          date: fuelRequestInfo.dateOfUplift,
          services: `${fuelRequestInfo.volume} gallons requested.`,
          price: `$${(
            calcRate(fuelRequestInfo.volume) * fuelRequestInfo.volume
          ).toFixed(2)}`,
          status,
        };

        return data;
      });
    }
  };

  const renderRequests = requests => {
    const data = requestData(requests || []);

    const columns = [
      {
        Header: props => {
          return <CellHeader color={colors.blueGray}>Registry</CellHeader>
        },
        accessor: 'registry',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            {props.value}
          </Cell>
        ),
      },
      {
        Header: props => (
          <CellHeader color={colors.blueGray}>Operator</CellHeader>
        ),
        accessor: 'operator',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            {props.value}
          </Cell>
        ),
      },
      {
        Header: props => <CellHeader color={colors.blueGray}>Name</CellHeader>,
        accessor: 'name',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            {props.value}
          </Cell>
        ),
      },
      {
        Header: props => (
          <CellHeader color={colors.blueGray}>Date of Uplift</CellHeader>
        ),
        accessor: 'date',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            {props.value}
          </Cell>
        ),
      },
      {
        Header: props => (
          <CellHeader color={colors.blueGray}>Services</CellHeader>
        ),
        accessor: 'services',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            {props.value}
          </Cell>
        ),
        minWidth: 300,
      },
      {
        Header: props => (
          <CellHeader color={colors.blueGray}>Total Price</CellHeader>
        ),
        accessor: 'price',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            {props.value}
          </Cell>
        ),
      },
      {
        Header: props => (
          <CellHeader color={colors.blueGray}>Status</CellHeader>
        ),
        accessor: 'status',
        Cell: props => (
          <Cell unbordered color={colors.charcoalGray}>
            <HighlightedText type={props.value === 'pending' && 'warn'}>
              {props.value}
            </HighlightedText>
          </Cell>
        ),
      },
    ];

    return (
      <DataTable
        data={data}
        columns={columns}
        showPagination={false}
        showPageSizeOptions={false}
        defaultPageSize={data.length}
        defaultSortDesc={true}
        defaultSorted={[
          {
            id: "status",
            desc: true
          }
        ]}
      />
    );
  };

  return (
    <ContentContainer maxWidth="1200px" margin="32px auto 0" flex="1 0 auto">
      <div style={{ width: '100%' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Heading color={colors.charcoalGrey}>Pending Requests</Heading>
          <Link to='/requests'>
            <GradientButton
              style={{ padding: '10px 20px' }}
            >
              View All Requests
            </GradientButton>
          </Link>
        </div>
        {requests.status === 'success' && renderRequests(requests.requests)}
      </div>
    </ContentContainer>
  );
};

export default PendingRequests;
