import styled, { css } from 'styled-components';

export default styled.button`
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width};
  border-radius: 9px;
  background-size: 50%;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 8px 0 rgba(17, 43, 78, 0.1);

  color: #fff;
  font-size: 16px;
  letter-spacing: NaNpx;
  line-height: 19px;
  text-align: center;

  ${props =>
    props.bold &&
    css`
      color: #fff;
      font-family: 'Helvetica Neue';
      font-size: 16px;
      font-weight: 700;
      letter-spacing: NaNpx;
      line-height: 19px;
      text-align: center;
    `}
`;
