import firebase from 'firebase';

import { firebaseApp } from '../../config/firebase';
import CollectionManager from './collection';

const Users = new CollectionManager('fbos');

/**
 * On auth state changed observer
 */

export const onAuthStateChanged = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        resolve(user);
      }
      reject(new Error('You are not signed in.'));
    });
  });
};

/**
 * Sign in to firebase with email and password credential
 */
export const loginWithEmailAndPassword = ({ email, password }) =>
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(res => JSON.stringify(res))
    .then(res => JSON.parse(res))
    .then(res => {
      return {
        token: res.user.stsTokenManager.refreshToken,
        uid: res.user.uid,
      };
    })
    .catch(err => {
      console.log('ERROR - sign in with email and password: ', err);
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
        default:
          throw err;
      }
    });

/**
 * Sign up with firebase with email and password credential
 */
export const signUpWithEmailAndPassword = ({ email, password }) => {
  return firebaseApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(res => JSON.stringify(res))
    .then(res => JSON.parse(res))
    .then(res => {
      if (res.user) {
        const data = {
          auth: {
            uid: res.user.uid,
            email: res.user.email,
          },
          isProfileComplete: false,
          isApproved: false,
        };

        Users.setDoc(res.user.uid, data);

        return {
          ...res.user,
          token: firebase.auth().currentUser.refreshToken,
        };
      }
    })
    .catch(err => {
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/email-already-in-use':
        case 'auth/operation-not-allowed':
        case 'auth/weak-password':
        default:
          throw err;
      }
    });
};

/**
 * Forgot password
 */
export const forgotPassword = ({ email }) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      // do email sent stuff
      console.log('email sent');
    })
    .catch(err => {
      console.log(err);
    });
};

/**
 * Sign out
 */
export const signOut = () => firebase.auth().signOut();
