import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#f6faff'};
  background-image: ${props => props.backgroundImage && props.backgroundImage}
`;
