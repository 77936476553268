import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Page, MainHeader, Main, Footer } from '../../ui/layout';
import { NavButton } from '../../ui/buttons/NavButton';
import colors from '../../../constants/colors';

import CommonPricing from './CommonPricing';
import AircraftPricing from './AircraftPricing';
import TaxBreakdown from './TaxBreakdown';
import servicesIcon from '../../../assets/svg/vector-icons-aircraft.svg';
import {IntroModal} from '../../ui/modals/IntroModal';

const CustomTab = props => {
  return (
    <Tab
      style={{
        display: 'flex',
        position: 'relative',
        top: 0,
        height: 53,
        borderRadius: 0,
        alignItems: 'flex-end',
      }}
      ref={el => {
        if (el) {
          el.node.style.setProperty('padding', 0, 'important');
        }
      }}
    >
      <NavButton
        color={colors.darkSlateBlue}
        bottomPadding="13px"
        active={props.selected}
      >
        {props.children}
      </NavButton>
    </Tab>
  );
};
CustomTab.tabsRole = 'Tab';

const TabsWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-left: 12px;

  @media (min-width: 1200px) {
    padding-left: 0;
  }
`;

class Services extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  renderPrivate() {
    return (
      <div style={{ flex: 1 }}>
        <Tabs>
          <TabList
            style={{
              background: 'white',
              border: 'none',
              height: '53px',
              margin: '0 auto',
            }}
          >
            <TabsWrapper>
              <CustomTab>Common Pricing</CustomTab>
              <CustomTab>Aircraft Specific Pricing</CustomTab>
              <CustomTab>Tax Breakdown</CustomTab>
            </TabsWrapper>
          </TabList>
          <TabPanel>
            <CommonPricing
              user={this.props.user}
              updateUser={this.props.updateUser}
            />
          </TabPanel>
          <TabPanel>
            <AircraftPricing
              user={this.props.user}
              updateUser={this.props.updateUser}
            />
          </TabPanel>
          <TabPanel>
            <TaxBreakdown
              user={this.props.user}
              updateUser={this.props.updateUser}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }

  render() {
    const { status, isApproved } = this.props.user;

    if (status === 'pending') {
      return <div>Loading...</div>;
    }

    return (
      <Page>
        <MainHeader title="Aircraft & Services" />
        <Main bgColor={colors.iceBlue} flexDirection="column">
          {isApproved ? this.renderPrivate() : <Redirect to="/approval" />}
        </Main>
        <IntroModal
            keyFlag="services"
            title="Aircraft & Services"
            icon={servicesIcon}
            contentText="Set default pricing as well as aircraft or operator specific pricing."/>
        <Footer />
      </Page>
    );
  }
}

export default Services;
