import React, { Component } from 'react';
import { Formik } from 'formik';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import chevronIcon from '../../../../assets/svg/vector-chevron.svg';
import searchIcon from '../../../../assets/svg/vector-icons-search.svg';
import { Form, TextInput } from '../../../ui/forms';
import { Section, ContentContainer } from '../../../ui/layout';
import colors from '../../../../constants/colors';
import styled from 'styled-components';
import SpecificPricingModal from './SpecificPricingModal';
import {
  TextContainer,
  EmptyText,
  Item,
  TextH2,
  TextH4,
  Heading,
} from './AircraftPricing.styled';

class AircraftPricing extends Component {
  state = { searchText: '', isDialogShown: false, dialogData: {} };

  aircraftSearch = () => {
    return (
      <Formik
        initialValues={{}}
        isInitialValid={false}
        // validationSchema={}
        onSubmit={values => {
          console.log(values);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldTouched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <TextInput
              onChange={event => {
                this.setState({ searchText: event.target.value });
                handleChange(event);
              }}
              onBlur={() => setFieldTouched('search')}
              type="text"
              name="rate"
              placeholder="Search by Aircraft type or Registry"
              value={values.search}
              error={touched.search && errors.search}
              width="100%"
              height="80px"
              padding="24px"
              fontSize="18px"
              icon={
                <img
                  src={searchIcon}
                  alt="icon-graphic"
                  style={{ width: '20px', margin: 'auto 21px' }}
                />
              }
            />
          </Form>
        )}
      </Formik>
    );
  };

  aircraftList = aircrafts => {
    return (
      <div style={{ width: '100%' }}>
        {(aircrafts.length > 0 &&
          aircrafts.map(aircraft => (
            <Item
              key={aircraft.uid}
              onClick={() => {
                this.setState({
                  isDialogShown: true,
                  dialogData: { type: aircraft.name },
                });
              }}
            >
              <TextContainer>
                <TextH4>Registry</TextH4>
                <TextH2>N288GA</TextH2>
              </TextContainer>
              <TextContainer flex={2}>
                <TextH4>Aircraft Type</TextH4>
                <TextH2>{aircraft.name}</TextH2>
              </TextContainer>
              <img
                src={chevronIcon}
                alt="icon-graphic"
                style={{ width: '12px', margin: '6px 24px' }}
              />
            </Item>
          ))) || <EmptyText>No items to display</EmptyText>}
      </div>
    );
  };

  render() {
    return (
      <ContentContainer
        maxWidth="1200px"
        margin="32px auto"
        flex="1 0 auto"
        flexDirection="column"
        justifyContent="flex-start"
        padding="0px"
      >
        <Section>
          <div style={{ width: '100%' }}>
            <Heading color={colors.charcoalGrey}>
              Aircraft Specific Pricing
            </Heading>
          </div>
        </Section>
        {this.aircraftSearch()}
        {this.state.searchText !== '' &&
          this.aircraftList(
            this.props.airplanes.airplanes.filter(obj => {
              return this.state.searchText !== ''
                ? obj.name.match(this.state.searchText)
                : true;
            })
          )}
        <SpecificPricingModal
          data={this.state.dialogData}
          show={this.state.isDialogShown}
          onModalClose={() => {
            this.setState({ isDialogShown: false });
          }}
        />
      </ContentContainer>
    );
  }
}

export default AircraftPricing;
