import styled from 'styled-components';

export default styled.h4`
  color: #90a3bc;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  
  > a, a:visited {
    color: #90a3bc;
    text-decoration: none;
  }
  > a:hover {
    text-decoration: underline;
  }
`;
