import { fork, call, put, takeLatest } from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './stripe.actionTypes';
import * as Stripe from '../../services/stripe';

import { getRequests as getRequestsAction } from '../requests/requests.actions';

import { createAsyncSaga } from '../utils';

function* chargeCustomer(action) {
  yield fork(createAsyncSaga, action, Stripe.chargeCustomer, action.payload);
}

function* chargeCustomerSuccess(action) {
  const response = action.payload.body.data;
  yield call(Firebase.updateRequestStatus, response.description, 'completed');
  yield put(getRequestsAction());
}

function* chargeCustomerFailure(action) {
  yield console.log(action);
}

/************* WATCHER SAGA ******************************/

export function* stripeSaga() {
  yield takeLatest(actionTypes.CHARGE_STRIPE_CUSTOMER.REQUEST, chargeCustomer);
  yield takeLatest(
    actionTypes.CHARGE_STRIPE_CUSTOMER.SUCCESS,
    chargeCustomerSuccess
  );
  yield takeLatest(
    actionTypes.CHARGE_STRIPE_CUSTOMER.FAILURE,
    chargeCustomerFailure
  );
}
