import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { updateRequest } from '../../../../../state/requests/requests.actions';
import { Table, Row, CellHeader } from '../../../../ui/tables';
import { TableContainer } from './ServicesTable.styled';
import AddRow from './AddRow';
import DisplayRow from './DisplayRow';
import EditRow from './EditRow';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const ServicesTable = props => {
  console.log('Services Table Props', props);
  const {
    labels,
    onSave,
    airplanes,
    aircraft,
    fuelVolume,
    requestId,
    updateRequest,
    services: { commonPricing, fuelPricing },
    servicesAdded,
    status,
    total: totalPrice,
  } = props;

  const calcPlaneSize = (airplanes, aircraft) => {
    const plane = airplanes.find(plane => plane.name === aircraft);
    return plane ? plane.size : 'large';
  };

  const transformData = (commonPricing, planeSize) => {
    return Object.keys(commonPricing).map(service => {
      const getPrice = planeSize => {
        switch (planeSize) {
          case 'small':
            return commonPricing[service].smallAircraft;
          case 'medium':
            return commonPricing[service].mediumAircraft;
          default:
            return commonPricing[service].largeAircraft;
        }
      };

      return {
        name: commonPricing[service].name,
        type: service === 'galToWaiveHandlingFee' ? 'GAL' : 'DOLLAR',
        values: {
          price: getPrice(planeSize),
        },
      };
    });
  };

  const [editRow, setEditRow] = useState(-1);
  const [services, setServices] = useState(servicesAdded || []);
  const [total, setTotal] = useState({
    name: 'Total',
    type: 'DOLLAR',
    values: {
      price: totalPrice || 0,
    },
  });
  const [planeSize, setPlaneSize] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (services.length === 0) {
      const calcFuelRate = (fuelVolume, fuelPricing) => {
        const { tier1, tier2, tier3 } = fuelPricing;

        if (fuelVolume > tier2.limitHigh) return tier3.rate;
        if (fuelVolume > tier1.limitHigh) return tier2.rate;
        return tier1.rate;
      };
      // use fuel pricing and fuel volume to create first service

      const fuelRate = calcFuelRate(fuelVolume, fuelPricing);
      const fuelService = {
        name: `${fuelVolume} Gallons Fuel ($${fuelRate.toFixed(2)} per gallon)`,
        type: 'DOLLAR',
        values: {
          price: fuelRate * fuelVolume,
        },
      };

      setServices([fuelService, ...services]);
    }

    if (!planeSize) {
      setPlaneSize(calcPlaneSize(airplanes, aircraft));
    } else if (!data) {
      setData(transformData(commonPricing, planeSize));
    }

    if (data && planeSize) {
      console.log('services', services[0].values.price);
      const total =
        services.reduce((total, service) => total + service.values.price, 0) ||
        0;

      console.log(total);

      setTotal({
        name: 'Total',
        type: 'DOLLAR',
        values: {
          price: total,
        },
      });

      updateRequest({ requestId, services, total });
    }
  }, [
    planeSize,
    commonPricing,
    airplanes,
    aircraft,
    data,
    services,

    requestId,
    updateRequest,
    fuelVolume,
    fuelPricing,
  ]);

  // useEffect(() => {
  //   setTotal({
  //     name: 'Total',
  //     type: 'DOLLAR',
  //     values: {
  //       price: total,
  //     },
  //   });
  // }, [total]);

  // useEffect(() => {
  //   console.log('services to be totaled', services);

  //   setTotal({
  //     name: 'Total',
  //     type: 'DOLLAR',
  //     values: {
  //       price: 4000,
  //     },
  //   });
  // }, [services, total]);

  // const totalPrice = {
  //   name: 'Total',
  //   type: 'DOLLAR',
  //   values: {
  //     price: total,
  //   },
  // };

  // const getTotalPrice = price => {
  //   return {
  //     name: 'Total',
  //     type: 'DOLLAR',
  //     values: {
  //       price,
  //     },
  //   };
  // };

  const headers = ['Price'];

  const editService = (service, values) => {
    const otherServices = services.filter(key => key.name !== service.name);
    const updatedService = {
      ...service,
      values,
    };

    return [...otherServices, updatedService];
  };

  // const total = {
  //   name: 'Total',
  //   type: 'DOLLAR',
  //   values: {
  //     price: 4000,
  //   },
  // };

  return (
    <TableContainer>
      <Table>
        <Row>
          <CellHeader flex={0.5} />
          <CellHeader flex={5} />
          {headers.map((header, k) => (
            <CellHeader key={`et-h-${k}`}>
              {labels[header] || header}
            </CellHeader>
          ))}
          <CellHeader flex={0.5} />
        </Row>
        {services.map((row, k) =>
          editRow === k ? (
            <EditRow
              row={row}
              key={`et-${k}`}
              setEditRow={() => setEditRow(-1)}
              onEditSave={values => setServices(editService(row, values))}
            />
          ) : (
            <DisplayRow
              row={row}
              disableEdit={status === 'completed'}
              setEditRow={() => setEditRow(k)}
              deleteRow={() => setServices(services.filter((_, i) => i !== k))}
              key={`et-${k}`}
            />
          )
        )}
        {status !== 'completed' && (
          <AddRow
            services={data}
            onSelect={service => setServices([...services, service])}
          />
        )}

        <DisplayRow
          row={total}
          // row={getTotalPrice(totalPrice)}
          disableEdit={true}
          key={`et-${12345}`}
        />
      </Table>
    </TableContainer>
  );
};

export default connect(
  null,
  { updateRequest }
)(ServicesTable);
