import React from 'react';
import { Pane } from 'evergreen-ui';

const DetailDialogPane = ({ label, value }) => {
  return (
    <Pane width="50%">
      <h5>{label}</h5>
      <p>{value}</p>
    </Pane>
  );
};

export default DetailDialogPane;
