import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Profile from './Profile';
import { logout } from '../../../state/auth/auth.actions';
import { getUser, updateUser } from '../../../state/user/user.actions';

const mapState = ({ auth, user }) => ({
  auth,
  user,
});

export default connect(
  mapState,
  { getUser, updateUser, push, logout }
)(Profile);
