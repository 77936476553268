import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Requests from './Requests';
import { logout } from '../../../state/auth/auth.actions';
import { getUser } from '../../../state/user/user.actions';
import { getRequests } from '../../../state/requests/requests.actions';
import { chargeCustomer } from '../../../state/stripe/stripe.actions';

const mapState = ({ auth, user, requests }) => ({
  auth,
  user,
  requests,
});

export default connect(
  mapState,
  { getUser, getRequests, chargeCustomer, push, logout }
)(Requests);
