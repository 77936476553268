import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseConfig = Object.freeze({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
});

export const cloudFunctions = Object.freeze({
  stripeApi: 'https://us-central1-nozl-34591.cloudfunctions.net/stripeApi',
});

export const firebaseApp = firebase.initializeApp(firebaseConfig);
