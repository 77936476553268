import React, { useState, useEffect } from 'react';
import ReactSVG from 'react-svg'
import { TimeSeries } from 'pondjs';
import {
  AreaChart,
  styler,
  Resizable,
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
} from 'react-timeseries-charts';
import { Tooltip } from 'evergreen-ui';

import numeral from 'numeral';

import requestsIconSvg from '../../../../assets/images/icons-dashboard-requests.svg';
import feedbackIconSvg from '../../../../assets/images/icons-dashboard-feedback.svg';
import earningsIconSvg from '../../../../assets/images/icons-dashboard-earnings.svg';

import {
  SubHeading,
  SubHeadingBody,
  SubHeadingTotal,
  ChartHeader,
  ChartHeaderFilter,
  ChartHeaderContent,
} from './Chart.styled';
import { ContentContainer } from '../../../ui/layout';
import { Select } from '../../../ui/forms';
import { Heading } from '../../../ui/tables';
import colors from '../../../../constants/colors';

const revenueStyle = styler([
  { key: 'revenue', color: colors.lightBlue, width: 3 }
]);

const revenueAreaStyle = styler([
  { key: 'revenue', color: colors.lightAquaMarine }
]);

const ChartHeadingIcon = (props) => {
  return <ReactSVG
      src={props.src}
      beforeInjection={svg => {
        svg.setAttribute('style', `width: ${props.width}; height: ${props.height}`);
      }}
  />
};

const Chart = ({ requests, feedback }) => {
  const allRequests = requests.requests || [];
  const numberOfRequests = allRequests.length;
  const numberOfFeedbacks = feedback.length;
  const [range, setRange] = useState();
  const [tracker, setTracker] = useState({});
  const revenue = allRequests.reduce((acc, request) => acc + (parseInt(request.fuelRequestInfo.price, 10) || 0), 0);
  const revenueData = new TimeSeries({
    name: 'revenue',
    columns: ['time', 'revenue'],
    points: allRequests.map(request => [
      request.created_at.toDate(),
      (parseInt(request.fuelRequestInfo.price, 10) || 0)
    ]).sort((a, b) => a[0] - b[0])
  });

  useEffect(() => {
    setRange(revenueData.timerange());
  }, [revenueData.count()]);

  const updateTimerange = (timerange) => {
    setRange(timerange);
  };

  const updateTracker = (t) => {
    if (t) {
      const e = revenueData.atTime(t);
      const eventValue = e.get('revenue');
      setTracker({ position: t, value: eventValue });
    } else {
      setTracker({ position: null, value: null });
    }
  };

  const renderChart = () => {
    return (
      <div>
        <ChartHeader>
          <ChartHeaderContent>
            <div style={{ flex: 0, marginRight: 15 }}>
              <ChartHeadingIcon
                src={requestsIconSvg}
                width={46}
                height={46}
              />
            </div>
            <div>
              <SubHeading color={colors.blueGray}>Requests</SubHeading>
              <SubHeadingBody>{numberOfRequests}</SubHeadingBody>
            </div>
          </ChartHeaderContent>
          <ChartHeaderContent>
            <div style={{ flex: 0, marginRight: 15 }}>
              <ChartHeadingIcon
                src={earningsIconSvg}
                width={46}
                height={46}
              />
            </div>
            <div>
              <SubHeading color={colors.blueGray}>FBO Revenue</SubHeading>
              <SubHeadingBody>
                {numeral(revenue).format('$0,0.00')}
              </SubHeadingBody>
            </div>
          </ChartHeaderContent>
          <ChartHeaderContent>
            <div style={{ flex: 0, marginRight: 15 }}>
              <ChartHeadingIcon
                src={feedbackIconSvg}
                width={46}
                height={46}
              />
            </div>
            <div>
              <SubHeading color={colors.blueGray}>Feedback</SubHeading>
              <SubHeadingBody>{numberOfFeedbacks}</SubHeadingBody>
            </div>
          </ChartHeaderContent>
        </ChartHeader>
        <div style={{ margin: 25 }}>
          <ChartHeader justify="space-between">
            <Heading>Request Activity</Heading>
            <ChartHeaderFilter>
              <SubHeadingTotal>
                Total: {numeral(revenue).format('$0,0.00')}
              </SubHeadingTotal>
            </ChartHeaderFilter>
          </ChartHeader>
          {numberOfRequests > 0 && range && (
            <div>
              <Resizable>
                <ChartContainer
                  enablePanZoom
                  timeRange={range}
                  onTimeRangeChanged={updateTimerange}
                  trackerPosition={tracker.position}
                  onTrackerChanged={updateTracker}
                  trackerValues={tracker.value}
                >
                  <ChartRow height={300}>
                    <YAxis
                      id="axis1"
                      type="linear"
                      min={revenueData.min('revenue')}
                      max={revenueData.max('revenue')}
                      format="$,.0f"
                      hideAxisLine
                      showGrid
                      tickCount={5}
                    />
                    <Charts>
                      <AreaChart
                        axis="axis1"
                        columns={{ up: ['revenue'], down: [] }}
                        series={revenueData}
                        style={revenueAreaStyle}
                      />
                      <LineChart 
                        axis="axis1"
                        columns={['revenue']}
                        series={revenueData}
                        style={revenueStyle}
                      />
                    </Charts>
                  </ChartRow>
                </ChartContainer>
              </Resizable>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <ContentContainer maxWidth="1200px" margin="32px auto 0" flex="1 0 auto">
      <div style={{ width: '100%' }}>{renderChart()}</div>
    </ContentContainer>
  );
};

export default Chart;
