import { fork, takeLatest, put } from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './user.actionTypes';
import * as actions from './user.actions';
import { createAsyncSaga } from '../utils';

/**********/
/* Profile */
/**********/

function* getUser(action) {
  yield fork(createAsyncSaga, action, Firebase.getProfile, {});
}

function* getUserSuccess(action) {}

function* getUserFailure(action) {
  // handle failure
}

function* updateUser(action) {
  yield fork(createAsyncSaga, action, Firebase.updateUser, action.payload);
}

function* updateUserSuccess(action) {
  yield put(actions.getUser());
}

function* updateUserFailure(action) {
  // handle failure
}

export function* userSaga() {
  // get user
  yield takeLatest(actionTypes.GET_USER.REQUEST, getUser);
  yield takeLatest(actionTypes.GET_USER.SUCCESS, getUserSuccess);
  yield takeLatest(actionTypes.GET_USER.FAILURE, getUserFailure);
  // update user
  yield takeLatest(actionTypes.UPDATE_USER.REQUEST, updateUser);
  yield takeLatest(actionTypes.UPDATE_USER.SUCCESS, updateUserSuccess);
  yield takeLatest(actionTypes.UPDATE_USER.FAILURE, updateUserFailure);
}
