import React, { useState } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { Formik } from 'formik';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Popover, Position, Menu, IconButton } from 'evergreen-ui';

import Table from './Table';
import Row from './Row';
import Cell from './Cell';
import CellHeader from './CellHeader';
import { GradientButton, GradientOutlineButton } from '../buttons';
import { TextInput } from '../forms';
import colors from '../../../constants/colors';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const ROW_FORMAT = (type, value) => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return numeral(value).format('$0,0.00');
    case ROW_TYPES.GAL:
      return `${value} gal`;
    default:
      return value;
  }
};

const ROW_VALUE_TYPE = type => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return 'number';
    case ROW_TYPES.GAL:
      return 'number';
    default:
      return '';
  }
};

const TableContainer = styled.div`
  flex: 1;
  padding: 30px 20px;
  border-radius: 16px;
  background-color: #f6faff;
`;

const IconWrapper = styled.div`
  background-color: ${props => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  margin-right: 18px;
`;

const FormRow = styled.form`
  display: flex;
  flex: 1 0 100%;
`;

const DisplayRow = props => {
  const { row, setEditRow, deleteRow } = props;
  return (
    <Row>
      <Cell flex={0.5}>
        <IconWrapper bgColor={colors.blueGray} onClick={() => setEditRow()}>
          <Icon icon={faPen} color="white" />
        </IconWrapper>
      </Cell>
      <Cell flex={5}>{row.name}</Cell>
      {Object.keys(row.values).map((name, i) => (
        <Cell key={`et-${i}-${row.values[name]}`}>
          {ROW_FORMAT(row.type, row.values[name])}
        </Cell>
      ))}
      <Cell flex={0.5}>
        <IconButton
          appearance="minimal"
          icon="trash"
          intent="danger"
          height={36}
          onClick={() => deleteRow()}
        />
      </Cell>
    </Row>
  );
};

const EditRow = props => {
  const { row, setEditRow: cancelEdit, onEditSave } = props;
  return (
    <Formik
      initialValues={row.values}
      isInitialValid
      // validationSchema={}
      onSubmit={values => {
        onEditSave(values);
        cancelEdit();
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
        isValid,
      }) => (
        <FormRow onSubmit={handleSubmit}>
          <Cell flex={0.5}>
            <IconWrapper bgColor={colors.blueGray} onClick={() => cancelEdit()}>
              <Icon icon={faTimes} color="white" />
            </IconWrapper>
          </Cell>
          <Cell flex={5}>
            {row.name}
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <GradientOutlineButton
                bold
                height="40px"
                width="150px"
                type="cancel"
                onClick={() => cancelEdit()}
                color={colors.brightLightBlue}
              >
                Cancel
              </GradientOutlineButton>
              <div style={{ width: '20px' }} />
              <GradientButton
                bold
                height="40px"
                width="150px"
                type="submit"
                disabled={!isValid}
              >
                Save Changes
              </GradientButton>
            </div>
          </Cell>
          {Object.keys(row.values).map((name, i) => (
            <Cell key={`et-${i}-${row.values[name]}`}>
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched(name)}
                type={ROW_VALUE_TYPE(row.type)}
                name={name}
                value={values[name]}
                error={touched[name] && errors[name]}
                label={ROW_FORMAT(row.type, values[name])}
                width="100%"
                padding="0px"
              />
            </Cell>
          ))}
        </FormRow>
      )}
    </Formik>
  );
};

const AddRow = props => {
  const { services, onSelect } = props;
  return (
    <Row>
      <Cell flex={0.5}>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              <Menu.Group>
                {services.map((service, k) => (
                  <Menu.Item
                    key={`service-${k}`}
                    onSelect={() => {
                      onSelect(service);
                      close();
                    }}
                  >
                    {service.name}
                  </Menu.Item>
                ))}
              </Menu.Group>
            </Menu>
          )}
        >
          <IconButton appearance="minimal" icon="plus" height={36} />
        </Popover>
      </Cell>
      <Cell flex={5}>Add Service</Cell>
    </Row>
  );
};

const ServicesTable = props => {
  console.log('Services Props', props);
  const { data, labels, onSave, style } = props;
  const [editRow, setEditRow] = useState(-1);
  const [services, setServices] = useState([]);
  const headers = [
    ...new Set(data.map(field => Object.keys(field.values)).flat()),
  ];

  return (
    <TableContainer>
      <Table>
        <Row>
          <CellHeader flex={0.5} />
          <CellHeader flex={5} />
          {headers.map((header, k) => (
            <CellHeader key={`et-h-${k}`}>
              {labels[header] || header}
            </CellHeader>
          ))}
          <CellHeader flex={0.5} />
        </Row>
        {services.map((row, k) =>
          editRow === k ? (
            <EditRow
              row={row}
              key={`et-${k}`}
              setEditRow={() => setEditRow(-1)}
              onEditSave={values => onSave({ row, values })}
            />
          ) : (
            <DisplayRow
              row={row}
              setEditRow={() => setEditRow(k)}
              deleteRow={() => setServices(services.filter((_, i) => i !== k))}
              key={`et-${k}`}
            />
          )
        )}
        <AddRow
          services={data}
          onSelect={service => setServices([...services, service])}
        />
      </Table>
    </TableContainer>
  );
};

export default ServicesTable;
