import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import {
  Page,
  MainHeader,
  Main,
  Footer,
  ContentContainer,
} from '../../ui/layout';
import colors from '../../../constants/colors';

import RequestModal from './RequestModal';
import fuelIcon from '../../../assets/svg/vector-icons-fuel.svg';
import { IntroModal } from '../../ui/modals/IntroModal';
import RequestsList from './RequestsList';

class Requests extends Component {
  state = {
    isDialogShown: false,
    details: null,
  };

  componentDidMount() {
    this.props.getUser();
    this.props.getRequests();
  }

  onModalClose = () => {
    this.setState({
      isDialogShown: false,
      details: null,
    });
    this.props.getRequests();
  };

  handleRequestClick = (data, index) => {
    this.setState({
      isDialogShown: true,
      details: data[index],
    });
  };

  requestData = requests => {
    console.log('Requests Props', this.props);
    if (requests && requests.length !== 0) {
      const { services } = this.props.user;

      return requests.map(request => {
        const {
          fuelRequestInfo,
          user,
          status,
          uid,
          created_at,
          services: servicesAdded,
          total,
        } = request;

        const calcRate = volume => {
          const { fuelPricing } = this.props.user.services;
          if (volume <= fuelPricing.tier1.limitHigh) {
            return fuelPricing.tier1.rate;
          } else if (volume <= fuelPricing.tier2.limitHigh) {
            return fuelPricing.tier2.rate;
          } else {
            return fuelPricing.tier3.rate;
          }
        };

        const data = {
          id: uid,
          registry: fuelRequestInfo.registry || 'Old data N/A',
          operator: user.operator || 'Old data N/A',
          aircraft: user.aircraft || 'Old data N/A',
          name: user.name,
          email: user.email || 'Old data N/A',
          phone: user.phone || 'Old data N/A',
          stripeId: user.stripeId,
          date: fuelRequestInfo.dateOfUplift,
          dateCreated: moment(created_at.toDate()).format('YYYY-MM-DD'),
          servicesTotal: `${fuelRequestInfo.volume} gallons requested.`,
          services,
          servicesAdded,
          volume: fuelRequestInfo.volume,
          price:
            total ||
            (calcRate(fuelRequestInfo.volume) * fuelRequestInfo.volume).toFixed(
              2
            ),
          // price: `$${(
          //   calcRate(fuelRequestInfo.volume) * fuelRequestInfo.volume
          // ).toFixed(2)}`,
          status,
        };

        return data;
      });
    }
  };

  renderPrivate() {
    const {
      requests,
      user: { services },
    } = this.props;
    const data = this.requestData(requests.requests);

    return (
      <ContentContainer maxWidth="1200px" margin="32px auto 0" flex="1 0 auto">
        <div style={{ width: '100%' }}>
          {requests.status === 'success' && (
            <RequestsList
              requests={data}
              services={services}
              handleRequestClick={this.handleRequestClick}
            />
          )}
        </div>
      </ContentContainer>
    );
  }

  render() {
    const { status, isApproved } = this.props.user;
    const { chargeCustomer } = this.props;

    if (status === 'pending') {
      return <div>Loading...</div>;
    }

    return (
      <Page>
        <MainHeader title="Fuel Requests" />
        <RequestModal
          charge={chargeCustomer}
          show={this.state.isDialogShown}
          details={this.state.details}
          onModalClose={this.onModalClose}
        />
        <Main bgColor={colors.iceBlue} flexDirection="column">
          {isApproved ? this.renderPrivate() : <Redirect to="/approval" />}
        </Main>
        <IntroModal
          keyFlag="requests"
          title="Fuel Requests"
          icon={fuelIcon}
          contentText="This is where you can manage all the fuel requests that come in. You can edit and complete requests that come in as well as manually create requests"
        />
        <Footer />
      </Page>
    );
  }
}

export default Requests;
