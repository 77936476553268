import React, { Component } from 'react';
import { Formik } from 'formik';

import {
  Page,
  ContentContainer,
  MainHeader,
  Main,
  Footer,
} from '../../ui/layout';
import { Form, TextInput, TextArea } from '../../ui/forms';
import { Subtitle } from '../../ui/text';
import { GradientButton } from '../../ui/buttons';
import colors from '../../../constants/colors';
import ProfileSchema from '../../../utils/forms/schema.profile';
import { InputContainer } from './Profile.styled';

class Profile extends Component {
  handleSubmit = values => {
    const user = {
      ...this.props.user,
      airport: {
        name: values.airport,
        icao: values.icao,
      },
      fbo: {
        name: values.name,
        address: values.address,
        city: values.city,
        managerName: values.managerName,
        contactName: values.contactName,
        email: values.email,
        mobileNumber: values.mobileNumber,
        landline: values.landline,
        area: values.area,
        notes: values.notes,
        uid: this.props.auth.uid,
      },
      services: {
        fuelPricing: {
          tier1: {
            rate: 0,
            limitLow: 0,
            limitHigh: 499,
          },
          tier2: {
            rate: 0,
            limitLow: 500,
            limitHigh: 999,
          },
          tier3: {
            rate: 0,
            limit: 1000,
          },
        },
      },
    };

    this.props.updateUser(user);
  };

  renderProfileForm = () => {
    const {
      airport,
      fbo: {
        name,
        address,
        area,
        city,
        contactName,
        mobileNumber,
        landline,
        email,
        managerName,
        notes,
      },
    } = this.props.user;

    return (
      <Formik
        initialValues={{
          airport: airport.name || '',
          icao: airport.icao || '',
          name: name || '',
          address: address || '',
          area: area || '',
          city: city || '',
          contactName: contactName || '',
          mobileNumber: mobileNumber || '',
          landline: landline || '',
          email: email || '',
          managerName: managerName || '',
          notes: notes || '',
        }}
        isInitialValid={false}
        validationSchema={ProfileSchema}
        onSubmit={values => this.handleSubmit(values)}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldTouched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            {console.log('values', values)}
            {console.log('errors', errors)}
            <InputContainer>
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                }}
              >
                <Subtitle>FBO Details</Subtitle>
              </div>
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('airport')}
                type="text"
                name="airport"
                value={values.airport}
                error={touched.airport && errors.airport}
                label="Airport that FBO resides in"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('name')}
                type="text"
                name="name"
                value={values.name}
                error={touched.name && errors.name}
                label="FBO name"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('icao')}
                type="text"
                name="icao"
                value={values.icao}
                error={touched.icao && errors.icao}
                label="ICAO Code"
              />
            </InputContainer>

            <InputContainer>
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                }}
              >
                <Subtitle>Location</Subtitle>
              </div>
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('address')}
                type="text"
                name="address"
                value={values.address}
                error={touched.address && errors.address}
                label="Physical address"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('city')}
                type="text"
                name="city"
                value={values.city}
                error={touched.city && errors.city}
                label="City"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('area')}
                type="text"
                name="area"
                value={values.area}
                error={touched.area && errors.area}
                label="Metropolitan area"
              />
            </InputContainer>

            <InputContainer>
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                }}
              >
                <Subtitle>Contact</Subtitle>
              </div>
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('contactName')}
                type="text"
                name="contactName"
                value={values.contactName}
                error={touched.contactName && errors.contactName}
                label="Contact name"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('managerName')}
                type="text"
                name="managerName"
                value={values.managerName}
                error={touched.managerName && errors.managerName}
                label="General manager name"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('mobileNumber')}
                type="text"
                name="mobileNumber"
                value={values.mobileNumber}
                error={touched.mobileNumber && errors.mobileNumber}
                label="Mobile number"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('landline')}
                type="text"
                name="landline"
                value={values.landline}
                error={touched.landline && errors.landline}
                label="Landline phone number"
              />
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched('email')}
                type="text"
                name="email"
                value={values.email}
                error={touched.email && errors.email}
                label="Contact email"
              />
            </InputContainer>
            <InputContainer>
              <TextArea
                onChange={handleChange}
                onBlur={() => setFieldTouched('notes')}
                type="textarea"
                name="notes"
                value={values.notes}
                error={touched.notes && errors.notes}
                label="Notes"
                height="100px"
              />
            </InputContainer>

            <div
              style={{
                padding: '20px',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <GradientButton
                bold
                height="50px"
                width="455px"
                type="submit"
                disabled={!isValid}
              >
                Save Changes
              </GradientButton>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    console.log('*** PROFILE PROPS ***', this.props);

    const {
      fbo: {
        name
      }
    } = this.props.user;

    return (
      <Page>
        <MainHeader title={name} />
        <Main bgColor={colors.iceBlue} flexDirection="column">
          <ContentContainer
            maxWidth="1200px"
            margin="32px auto"
            flex="1 0 auto"
            flexDirection="column"
            alignItems="center"
            padding="0px"
          >
            {this.props.user.status === 'success' && this.renderProfileForm()}
          </ContentContainer>
        </Main>
        <Footer />
      </Page>
    );
  }
}

export default Profile;
