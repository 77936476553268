import React from 'react';
import styled from 'styled-components';

import colors from '../../../constants/colors';

const SelectInput = styled.select`
  height: 50px;
  width: ${props => props.width || '142px'};
  border: 0px;
  border-radius: 8px;
  background-color: #f6faff;
  padding-left: 12px;
  color: #89a2c0;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  line-height: 19px;
`;

const Label = styled.label`
  display: block;
  padding-bottom: 6px;
  color: #90a3bc;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
`;

const Error = styled.span`
  display: block;
  padding-top: 6px;
  padding-left: 6px;
  color: red;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
`;

const SelectContainer = styled.div`
  padding: 20px;
`;

const Select = ({
  onChange,
  onBlur,
  name,
  value,
  options,
  placeholder,
  label,
  error,
}) => {
  return (
    <SelectContainer key={name}>
      {label && <Label>{label}</Label>}
      <SelectInput
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        placeholder={placeholder}
        error={error}
      >
        {placeholder && (
          <option value="" hidden>
            {placeholder}
          </option>
        )}
        {options.map((option, k) => (
          <option key={`select-${name}-${k}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
      <Error>{error}</Error>
    </SelectContainer>
  );
};

export default Select;
