import { put, call } from 'redux-saga/effects';

import createAsyncAction from './createAsyncAction';

export default function* createAsyncSaga(action, apiFn, payload) {
  const asyncAction = createAsyncAction(action.type);
  try {
    const response = yield call(apiFn, payload);
    console.log(`[ASYNC SAGA SUCCESS - ${action.type}]: `, response);
    yield put(asyncAction.success(response));
  } catch (error) {
    console.log(`[ASYNC SAGA FAILURE - ${action.type}]: `, error);
    yield put(asyncAction.failure(error));
  }
}
