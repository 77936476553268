import React, { Component } from 'react';
import { Formik } from 'formik';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import {
  IconWrapper,
  Heading,
  SubHeading,
  TierBox,
  TierHeader,
  GallonText,
  GallonSubtext,
} from './CommonPricing.styled';
import { data, labels } from './CommonPricing.mockData';
import { Form, TextInput } from '../../../ui/forms';
import { GradientButton, GradientOutlineButton } from '../../../ui/buttons';
import { EditableTable } from '../../../ui/tables';
import { Section, ContentContainer } from '../../../ui/layout';
import colors from '../../../../constants/colors';
import camelize from '../../../../utils/functions/camelize';

class CommonPricing extends Component {
  state = {
    selectedTier: null,
  };

  constructServicesDataTemp = () => {
    const { commonPricing = {} } = this.props.user.services;

    return data.map(service => {
      const field = camelize(service.name).replace(/-/g, '');

      return {
        ...service,
        values: {
          ...commonPricing[field],
        },
      };
    });
  };

  handleServicesSubmit = (row, values) => {
    const { services } = this.props.user;
    const { commonPricing } = services;
    const { name: fieldName } = row;
    const field = camelize(fieldName).replace(/-/g, '');

    const updatedServices = {
      ...services,
      commonPricing: {
        ...commonPricing,
        [field]: { ...commonPricing[field], ...values },
      },
    };

    this.props.updateUser({ services: updatedServices });
  };

  handleTierPricingSubmit(tier, rate) {
    const { services } = this.props.user;
    const { fuelPricing } = services;

    const updatedServices = {
      ...services,
      fuelPricing: {
        ...fuelPricing,
        [tier]: {
          ...fuelPricing[tier],
          rate,
        },
      },
    };

    this.props.updateUser({ services: updatedServices });
    this.setState({ selectedTier: null });
  }

  toggleSelectedTier = tier => {
    this.state.selectedTier === tier
      ? this.setState({ selectedTier: null })
      : this.setState({ selectedTier: tier });
  };

  tierEditForm = tier => {
    const { fuelPricing } = this.props.user.services;

    return (
      <Formik
        initialValues={{
          rate: fuelPricing[tier].rate,
        }}
        isInitialValid={false}
        // validationSchema={}
        onSubmit={values => {
          const { rate } = values;
          this.handleTierPricingSubmit(tier, rate);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldTouched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <TextInput
              onChange={handleChange}
              onBlur={() => setFieldTouched('rate')}
              type="number"
              name="rate"
              value={values.rate}
              error={touched.rate && errors.rate}
              label="Rate"
              width="100%"
              padding="0px"
            />
            <GallonSubtext color={colors.blueGray}>
              {this.getGallonText(tier)}
            </GallonSubtext>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flex: '0 0 46%' }}>
                <GradientOutlineButton
                  bold
                  height="40px"
                  width="100%"
                  type="cancel"
                  onClick={() => this.setState({ selectedTier: null })}
                  color={colors.brightLightBlue}
                >
                  Cancel
                </GradientOutlineButton>
              </div>
              <div style={{ flex: '0 0 46%' }}>
                <GradientButton
                  bold
                  height="40px"
                  width="100%"
                  type="submit"
                  disabled={!isValid}
                >
                  Save
                </GradientButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  getGallonText = tier => {
    switch (tier) {
      case 1:
        return '0-499 gallons';
      case 2:
        return '500-999 gallons';
      case 3:
        return '1,000+ gallons';
      default:
        return null;
    }
  };

  renderTier = (tier, data) =>
    this.state.selectedTier === tier ? (
      <div style={{ width: '100%' }}>
        <TierHeader color={colors.charcoalGrey}>{`Tier ${tier}`}</TierHeader>

        {this.tierEditForm(`tier${tier}`)}
      </div>
    ) : (
      <div style={{ width: '100%' }}>
        <TierHeader color={colors.charcoalGrey}>{`Tier ${tier}`}</TierHeader>
        <GallonText>{`$${data.rate.toFixed(2)}/gal`}</GallonText>
        <GallonSubtext color={colors.blueGray}>
          {this.getGallonText(tier)}
        </GallonSubtext>
      </div>
    );

  renderTiers = () => {
    const { tier1, tier2, tier3 } = this.props.user.services.fuelPricing;

    return (
      <Section margin={0}>
        <TierBox borderColor={colors.veryLightBlue}>
          <IconWrapper
            bgColor={colors.blueGray}
            onClick={() => this.toggleSelectedTier(1)}
          >
            <Icon icon={faPen} color="white" />
          </IconWrapper>
          {this.renderTier(1, tier1)}
        </TierBox>

        <TierBox borderColor={colors.veryLightBlue}>
          <IconWrapper
            bgColor={colors.blueGray}
            onClick={() => this.toggleSelectedTier(2)}
          >
            <Icon icon={faPen} color="white" />
          </IconWrapper>
          {this.renderTier(2, tier2)}
        </TierBox>

        <TierBox borderColor={colors.veryLightBlue}>
          <IconWrapper
            bgColor={colors.blueGray}
            onClick={() => this.toggleSelectedTier(3)}
          >
            <Icon icon={faPen} color="white" />
          </IconWrapper>
          {this.renderTier(3, tier3)}
        </TierBox>
      </Section>
    );
  };

  renderServices = () => {
    return (
      <Section padding="0 0 30px 0">
        <div style={{ width: '100%' }}>
          <SubHeading color={colors.blueGray}>Services</SubHeading>
        </div>
        <EditableTable
          labels={labels}
          data={this.constructServicesDataTemp()}
          onSave={({ row, values }) => {
            this.handleServicesSubmit(row, values);
          }}
        />
      </Section>
    );
  };

  render() {
    return (
      <ContentContainer
        maxWidth="1200px"
        margin="32px auto"
        flex="1 0 auto"
        flexDirection="column"
        justifyContent="flex-start"
        padding="0px"
      >
        <Section>
          <div style={{ width: '100%' }}>
            <Heading color={colors.charcoalGrey}>
              Common Prices Across Aircraft
            </Heading>
          </div>
        </Section>
        {this.renderTiers()}
        {this.renderServices()}
      </ContentContainer>
    );
  }
}

export default CommonPricing;
