import { throttle, takeLatest, fork, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as Firebase from '../../../services/firebase';
import { createAsyncSaga } from '../../utils';
import { SIGN_UP_WITH_EMAIL } from '../auth.actionTypes';

function* signUp(action) {
  yield fork(
    createAsyncSaga,
    action,
    Firebase.signUpWithEmailAndPassword,
    action.payload
  );
}

function* signUpSuccess(action) {
  yield put(push('/create-profile'));
}

function* signUpFailure(action) {
  // show error message
}

export function* signUpSaga() {
  yield throttle(1000, SIGN_UP_WITH_EMAIL.REQUEST, signUp);
  yield takeLatest(SIGN_UP_WITH_EMAIL.SUCCESS, signUpSuccess);
  yield takeLatest(SIGN_UP_WITH_EMAIL.FAILURE, signUpFailure);
}
