import { createAction } from '../utils';
import * as actionTypes from './requests.actionTypes';

export const getRequests = () =>
  createAction(actionTypes.GET_REQUESTS.REQUEST, {});

export const updateRequest = ({ requestId, services, total }) =>
  createAction(actionTypes.UPDATE_REQUEST.REQUEST, {
    requestId,
    services,
    total,
  });

export const updateRequestStatus = ({ requestId, status }) =>
  createAction(actionTypes.UPDATE_REQUEST_STATUS, { requestId, status });
