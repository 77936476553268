import * as actionTypes from './airplanes.actionTypes';
import { LOG_OUT } from '../auth/auth.actionTypes';

const initialState = {
  airplanes: [],
  status: 'pending',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AIRPLANES.REQUEST:
      return { ...state };
    case actionTypes.GET_AIRPLANES.SUCCESS:
      return {
        ...state,
        airplanes: action.payload,
        status: 'success',
      };

    case actionTypes.GET_AIRPLANES.FAILURE:
      return { ...state, status: 'failed' };

    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
