import { fork, takeLatest } from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './services.actionTypes';
import { createAsyncSaga } from '../utils';

function* testServices(action) {
  yield fork(createAsyncSaga, action, Firebase.testNestedCreate, {});
}

function* testServicesSuccess(action) {
  // handle success
}

function* testServicesFailure(action) {
  // handle failure
}

export function* servicesSaga() {
  yield takeLatest(actionTypes.TEST_SERVICES.REQUEST, testServices);
  yield takeLatest(actionTypes.TEST_SERVICES.SUCCESS, testServicesSuccess);
  yield takeLatest(actionTypes.TEST_SERVICES.FAILURE, testServicesFailure);
}
