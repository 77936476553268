import React from 'react';
import { Popover, Menu, Position, Button } from 'evergreen-ui';

import { Header, Container, ButtonsContainer, TitleContainer } from './MainHeader.styled';
import { NavButton } from '../../buttons';
import { LogoWithText } from '../../logos/LogoWithText';

export default ({ push, location, name, logout, title }) => {
  const renderButtons = () => {
    return (
      <ButtonsContainer>
        <div
          style={{
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'flex-end',
            paddingRight: '32px',
            marginBottom: '12px',
          }}
        >
          <NavButton onClick={() => push('/dashboard')}
                     active={location==='/dashboard'}>
            Dashboard
          </NavButton>
          <NavButton onClick={() => push('/requests')}
                     active={location==='/requests'}>
            Requests
          </NavButton>
          <NavButton onClick={() => push('/services')}
                     active={location==='/services'}>
            Aircrafts & Services
          </NavButton>
          <NavButton onClick={() => push('/feedback')}
                     active={location==='/feedback'}>
            Feedback
          </NavButton>
        </div>
      </ButtonsContainer>
    );
  };

  const renderUser = () => {
    return (
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <Menu>
            <Menu.Group>
              <Menu.Item
                onSelect={() => push('/profile')}
              >
                Account Settings
              </Menu.Item>
              <Menu.Item
                onSelect={() => logout()}
              >
                Logout
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }
      >
        <Button
          appearance="minimal"
          marginTop={16}
          marginRight={16}
          height={40}
          color="#FFF"
        >
            {name}
        </Button>
      </Popover>
      
    );
  };

  return (
    <Header>
      <Container>
        <LogoWithText />
        {renderButtons()}
        {renderUser()}
      </Container>
      { title && <TitleContainer><h2>{title}</h2></TitleContainer> }
    </Header>
  );
};
