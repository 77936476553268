import styled from 'styled-components';

import colors from '../../../../constants/colors';

export default styled.p`
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: ${props => (props.fontWeight && props.fontWeight || 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.color ? props.color : colors.charcoalGrey)};
  text-align: ${props => (props.textAlign && props.textAlign)};
`;
