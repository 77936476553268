import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import PendingRequests from './PendingRequests';
import Feedback from './Feedback';
import Chart from './Chart';

import { Page, MainHeader, Main, Footer } from '../../ui/layout';
import { IntroModal } from '../../ui/modals';

import colors from '../../../constants/colors';

import dashboardIcon from '../../../assets/svg/vector-icons-dashboard.svg';

class Dashboard extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getAirplanes();
    this.props.getRequests();
    this.props.getFeedback();
    // this.props.testServices();
  }

  renderPrivate() {
    const { requests, feedback, user } = this.props;

    return (
      <Fragment>
        <Chart feedback={feedback.feedback} requests={requests} />
        <PendingRequests requests={requests} services={user.services} />
        <Feedback feedback={feedback} />
      </Fragment>
    );
  }

  render() {
    const { user } = this.props;

    if (this.props.user.status === 'pending') {
      return <div>Loading...</div>;
    }

    return (
      <Page>
        <MainHeader title="Dashboard" />
        <Main bgColor={colors.iceBlue} flexDirection="column">
          {user.isApproved ? this.renderPrivate() : <Redirect to="/approval" />}
        </Main>
        <IntroModal
          keyFlag="dashboard"
          title="Dashboard"
          icon={dashboardIcon}
          contentText="The Dashboard gives you an overview of your sales, pending requests and recent feedback. Use this page to get a snap shot your FBO"
        />
        <Footer />
      </Page>
    );
  }
}

export default Dashboard;
