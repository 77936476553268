import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../assets/styles/react-table-custom.css';

const DataTable = props => {
  return (
    <ReactTable
      {...props}
      getProps={(state, rowInfo, column) => {
        return {
          style: {
            border: 0,
          },
        };
      }}
      getTheadProps={(state, rowInfo, column) => {
        return {
          style: {
            boxShadow: 'none',
          },
        };
      }}
      getTheadThProps={(state, rowInfo, column) => {
        return {
          style: {
            border: 0,
          },
        };
      }}
      getTdProps={(state, rowInfo, column) => {
        return {
          style: {
            border: 0,
            display: 'flex',
            alignItems: "columns"
          },
        };
      }}
    />
  );
};

export default DataTable;
