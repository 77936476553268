import React from 'react';

import colors from '../../../constants/colors';

const Form = props => {
  return (
    <div style={{ maxWidth: '100%' }}>
      <form onSubmit={props.onSubmit}>{props.children}</form>
    </div>
  );
};

export default Form;
