import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Login from './Login';
import { login } from '../../../state/auth/auth.actions';

const mapState = ({ auth }) => ({ auth });

export default connect(
  mapState,
  { login, push }
)(Login);
