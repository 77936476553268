import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Pane } from 'evergreen-ui';
import SpecificPricing from './SpecificPricing';

const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const labels = {
  price: 'Price',
};

const data = [
  {
    name: 'Hangar Fee',
    type: ROW_TYPES.DOLLAR,
    values: {
      price: 300,
    },
  },
  {
    name: 'Ramp Fee',
    type: ROW_TYPES.DOLLAR,
    values: {
      price: 300,
    },
  },
  {
    name: 'Gal to Waive Handling Fee',
    type: ROW_TYPES.GAL,
    values: {
      price: 50,
    },
  },
  {
    name: 'Handling Fee',
    type: ROW_TYPES.DOLLAR,
    values: {
      price: 50,
    },
  },
  {
    name: 'De-icing per gallon',
    type: ROW_TYPES.DOLLAR,
    values: {
      price: 50,
    },
  },
];

const SpecificPricingModal = props => {
  const {
    show,
    details,
    onModalClose,
    charge,
    services,
    airplanes: { airplanes },
  } = props;
  // const total = data.reduce((acc, service) => acc + service.values.price, 0);

  // console.log('Data to pass to services tables', data);
  console.log('Request modal services props', services);
  console.log('Request modal details props', details);
  console.log('Request modal airplanes props', airplanes);

  return (
    <Dialog
      isShown={show}
      title={
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingRight="10px"
        >
          <p>Specific Aircraft Pricing</p>
        </Pane>
      }
      width={900}
      onCloseComplete={onModalClose}
      hasFooter={false}
      contentContainerProps={{ style: { padding: 0 } }}
    >
      <SpecificPricing
        services={props.services}
        updateUser={() => {}}
        {...props}
      />
    </Dialog>
  );
};

const mapState = ({ user: { services }, airplanes }) => ({
  services,
  airplanes,
});

export default connect(mapState)(SpecificPricingModal);
