import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  border: ${props => props.border && props.border};
  margin: ${props => props.margin !== undefined ? props.margin : '0 32px'};
  padding: ${props => props.padding !== undefined ? props.padding : '0'};
`;

const Section = props => {
  return <StyledSection {...props}>{props.children}</StyledSection>;
};

export default Section;
