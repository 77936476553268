import React, { Component } from 'react';
import { Formik } from 'formik';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Heading } from './TaxBreakdown.styled';
import { Form, TextInput } from '../../../ui/forms';
import { Section, ContentContainer } from '../../../ui/layout';
import { FeesTable } from '../../../ui/tables';
import colors from '../../../../constants/colors';

class TaxBreakdown extends Component {
  state = {};
  render() {
    return (
      <ContentContainer
        maxWidth="1200px"
        margin="32px auto"
        flex="1 0 auto"
        flexDirection="column"
        justifyContent="flex-start"
        padding="0px"
      >
        <Section>
          <div style={{ width: '100%' }}>
            <Heading color={colors.charcoalGrey}>
              Tax Breakdown (applied to all transactions)
            </Heading>
            <FeesTable data={[
              {
                name: 'Taxes',
                value: '0.1'
              },
              {
                name: 'Fees',
                value: '0.03'
              }
            ]} />
          </div>
        </Section>
      </ContentContainer>
    );
  }
}

export default TaxBreakdown;
