// import * as actionTypes from './stripe.actionTypes';

const initialState = {
  status: 'pending',
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
