import { fork, takeLatest } from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './feedback.actionTypes';
import { createAsyncSaga } from '../utils';

function* getFeedback(action) {
  yield fork(createAsyncSaga, action, Firebase.getFeedback, {});
}

function* getFeedbackSuccess(action) {
  // handle success
}

function* getFeedbackFailure(action) {
  // handle failure
}

export function* feedbackSaga() {
  yield takeLatest(actionTypes.GET_FEEDBACK.REQUEST, getFeedback);
  yield takeLatest(actionTypes.GET_FEEDBACK.SUCCESS, getFeedbackSuccess);
  yield takeLatest(actionTypes.GET_FEEDBACK.FAILURE, getFeedbackFailure);
}
