import React from 'react';

import { Popover, Position, Menu, IconButton } from 'evergreen-ui';

import { Row, Cell } from '../../../../ui/tables';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const AddRow = props => {
  const { services, onSelect } = props;
  return (
    <Row>
      <Cell flex={0.5}>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              <Menu.Group>
                {services.map((service, k) => (
                  <Menu.Item
                    key={`service-${k}`}
                    onSelect={() => {
                      onSelect(service);
                      close();
                    }}
                  >
                    {service.name}
                  </Menu.Item>
                ))}
              </Menu.Group>
            </Menu>
          )}
        >
          <IconButton appearance="minimal" icon="plus" height={36} />
        </Popover>
      </Cell>
      <Cell flex={5}>Add Service</Cell>
    </Row>
  );
};

export default AddRow;
