import styled from 'styled-components';

import colors from '../../../../constants/colors';

export default styled.p`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.28px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  margin: 0;
  border-radius: 24px;
  background-color: ${props => (props.type==="warn" ? colors.lightPink : colors.lightGreen)};
  color: ${props => (props.type==="warn" ? colors.darkishPink : colors.mediumGreen)};
  text-transform: uppercase;
  width: 94px;
  height: 35px;
  padding-top: 10px;
`;
