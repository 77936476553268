import React from 'react';
import { Pane } from 'evergreen-ui';

const HeaderDialogPane = ({ label, value }) => {
  return (
    <Pane width="50%">
      <h4>{label}</h4>
      <p>{value}</p>
    </Pane>
  );
};

export default HeaderDialogPane;
