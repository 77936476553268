import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Pane } from 'evergreen-ui';

import colors from '../../../../constants/colors';
import { HighlightedText } from '../../../ui/text';
import { GradientButton } from '../../../ui/buttons';
import DetailDialogPane from './DetailDialogPane';
import HeaderDialogPane from './HeaderDialogPane';
import ServicesTable from './ServicesTable/index';

const labels = {
  price: 'Price',
};

const RequestModal = props => {
  console.log('Request Modal Props', props);
  const {
    show,
    details,
    onModalClose,
    charge,
    services,
    airplanes: { airplanes },
  } = props;
  // const total = data.reduce((acc, service) => acc + service.values.price, 0);

  return (
    <Dialog
      isShown={show}
      title={
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingRight="10px"
        >
          <p>Fuel Request Details</p>
          {details && (
            <HighlightedText type={details.status === 'pending' && 'warn'}>
              {details.status}
            </HighlightedText>
          )}
        </Pane>
      }
      width={900}
      onCloseComplete={onModalClose}
      hasFooter={false}
    >
      {details ? (
        <Pane>
          <Pane margin="20px" display="flex" flexDirection="row">
            <HeaderDialogPane label="Registry" value={details.registry} />
            <HeaderDialogPane label="Operator" value={details.operator} />
          </Pane>
          <Pane margin="20px">
            <h4>Details</h4>
            <Pane
              borderRadius="16px"
              backgroundColor={colors.iceBlue}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              padding="20px"
            >
              <DetailDialogPane label="Name" value={details.name} />
              <DetailDialogPane label="Email" value={details.email} />
              <DetailDialogPane label="Phone number" value={details.phone} />
              <DetailDialogPane label="Date of uplift" value={details.date} />
              <DetailDialogPane
                label="Date requested"
                value={details.dateCreated}
              />
              {details.dateCompleted && (
                <DetailDialogPane
                  label="Date of completion"
                  value={details.dateCompleted}
                />
              )}
            </Pane>
          </Pane>
          <Pane margin="20px">
            <h4>Services</h4>
            <Pane
              borderRadius="16px"
              backgroundColor={colors.iceBlue}
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              padding="20px"
            >
              <ServicesTable
                labels={labels}
                services={services}
                servicesAdded={details.servicesAdded}
                total={details.price}
                airplanes={airplanes}
                fuelVolume={details.volume}
                requestId={details.id}
                aircraft={details.aircraft}
                registry={details.registry}
                status={details.status}
                onSave={({ row, values }) => {
                  console.log('row and values', row, values);
                }}
              />
            </Pane>
          </Pane>
          <Pane margin="20px" display="flex" justifyContent="center">
            {details.status === 'pending' && (
              <GradientButton
                bold
                height="50px"
                width="300px"
                onClick={() => {
                  // const amount = Number(details.price.replace(/[^0-9-]+/g, ''));
                  console.log('Amount to charge', details.price);
                  const amount = details.price * 100;
                  charge(details.stripeId, amount, details.id);
                  onModalClose();
                }}
              >
                Complete Request
              </GradientButton>
            )}
          </Pane>
        </Pane>
      ) : (
        <h4>Loading...</h4>
      )}
    </Dialog>
  );
};

const mapState = ({ user: { services }, airplanes }) => ({
  services,
  airplanes,
});

export default connect(mapState)(RequestModal);
