import React from 'react';
import styled from 'styled-components';

const StyledMain = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  min-width: 100%;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
  background-color: ${props => props.bgColor && props.bgColor};
`;

const Main = ({ children, ...rest }) => {
  return <StyledMain {...rest}>{children}</StyledMain>;
};

export default Main;
