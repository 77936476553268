import * as actionTypes from './requests.actionTypes';
import { LOG_OUT } from '../auth/auth.actionTypes';

const initialState = {
  requests: [],
  status: 'pending',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REQUESTS.REQUEST:
      return { ...state };
    case actionTypes.GET_REQUESTS.SUCCESS:
      return {
        ...state,
        requests: action.payload,
        status: 'success',
      };

    case actionTypes.GET_REQUESTS.FAILURE:
      return { ...state, status: 'failed' };

    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
