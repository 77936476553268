import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';

import { configureStore, history } from '../src/state/store';

import './index.css';
import App from './App';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

const { store, persistor } = configureStore;

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App>{Routes}</App>
      </ConnectedRouter>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
