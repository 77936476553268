import React from 'react';
import styled from 'styled-components';

import colors from '../../../constants/colors';

const Area = styled.textarea`
  height: ${props => props.height || '100px'};
  width: ${props => props.width || '910px'};
  @media (max-width: 1024px) {
    width: 500px;
  }
  border: 0px;
  border-radius: 8px;
  background-color: #f6faff;
  padding-left: 12px;
  color: #89a2c0;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  line-height: 19px;
`;

const Label = styled.label`
  display: block;
  padding-bottom: 6px;
  color: #90a3bc;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
`;

const Error = styled.span`
  display: block;
  padding-top: 6px;
  padding-left: 6px;
  color: red;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
`;

const InputContainer = styled.div`
  padding: 20px;
`;

const TextArea = ({
  onChange,
  onBlur,
  type,
  name,
  value,
  placeholder,
  label,
  error,
  height,
  width,
}) => {
  return (
    <InputContainer key={name}>
      <Label>{label}</Label>
      <Area
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        label={label}
        error={error}
        height={height}
        width={width}
      />
      <Error>{error}</Error>
    </InputContainer>
  );
};

export default TextArea;
