import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import {
  Page,
  MainHeader,
  Main,
  Footer,
  ContentContainer,
} from '../../ui/layout';
import { Table, Row, Cell, CellHeader, Heading } from '../../ui/tables';
import { Rating } from '../../ui/rating';
import colors from '../../../constants/colors';
import feedbackIcon from '../../../assets/svg/vector-icons-feedback.svg';
import {IntroModal} from '../../ui/modals/IntroModal';

class Feedback extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getFeedback();
  }

  renderFeedbackData = feedbacks => {
    if (feedbacks) {
      return feedbacks.map(feedback => {
        const {
          comments,
          created_at,
          rating,
          uid,
          user = {
            registry: 'TEST12',
            operator: 'Avalair',
            name: 'John Smith',
          },
        } = feedback;

        const { registry, operator, name } = user;

        const date = moment(created_at.toDate()).format('YYYY-MM-DD');

        return (
          <Row key={uid}>
            <Cell color={colors.charcoalGray}>{registry}</Cell>
            <Cell color={colors.charcoalGray}>{operator}</Cell>
            <Cell color={colors.charcoalGray}>{name}</Cell>
            <Cell color={colors.charcoalGray}>{date}</Cell>
            <Cell color={colors.charcoalGray}>
              <Rating currentRating={rating} />
            </Cell>
            <Cell flex={5} color={colors.charcoalGray}>
              {comments}
            </Cell>
          </Row>
        );
      });
    }
  };

  renderFeedback = feedback => {
    return (
      <Table>
        <Row>
          <CellHeader color={colors.blueGray}>Registry</CellHeader>
          <CellHeader color={colors.blueGray}>Operator</CellHeader>
          <CellHeader color={colors.blueGray}>Name</CellHeader>
          <CellHeader color={colors.blueGray}>Date</CellHeader>
          <CellHeader color={colors.blueGray}>Rating</CellHeader>
          <CellHeader flex={5} color={colors.blueGray}>
            Feedback
          </CellHeader>
        </Row>
        {this.renderFeedbackData(feedback)}
      </Table>
    );
  };

  renderPrivate() {
    const { feedback } = this.props;

    return (
      <ContentContainer maxWidth="1200px"  margin="32px auto" flex="1 0 auto">
        <div style={{ width: '100%' }}>
          {feedback.status === 'success' &&
            this.renderFeedback(feedback.feedback)}
        </div>
      </ContentContainer>
    );
  }

  render() {
    const { status, isApproved } = this.props.user;

    if (status === 'pending') {
      return <div>Loading...</div>;
    }

    return (
      <Page>
        <MainHeader title="Feedback" />
        <Main bgColor={colors.iceBlue} flexDirection="column">
          {isApproved ? this.renderPrivate() : <Redirect to="/approval" />}
        </Main>
        <IntroModal
            keyFlag="feedback"
            title="Feedback"
            icon={feedbackIcon}
            contentText="View all feedback received from customers here"/>
        <Footer />
      </Page>
    );
  }
}

export default Feedback;
