import React from 'react';
import styled from 'styled-components';

import colors from '../../../constants/colors';

const InputWrapper = styled.div`
  height: ${props => props.height || '50px'};
  width: ${props => props.width || '455px'};
  border-radius: 8px;
  background-color: #f6faff;
  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  height: ${props => props.height || '50px'};
  width: ${props => props.width || '455px'};
  border: 0px;
  background-color: transparent;
  padding-left: 16px;
  color: #89a2c0;
  font-family: 'Helvetica Neue';
  font-size: ${props => props.fontSize || '16px'};
  line-height: 19px;
  &:focus {
    outline: none;
  }
`;

const Label = styled.label`
  display: block;
  padding-bottom: 6px;
  color: #90a3bc;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
`;

const Error = styled.span`
  display: block;
  padding-top: 6px;
  padding-left: 6px;
  color: red;
  font-family: 'Helvetica Neue';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
`;

const InputContainer = styled.div`
  padding: ${props => props.padding || '20px'};
`;

const TextInput = ({
  onChange,
  onBlur,
  type,
  name,
  value,
  placeholder,
  label,
  error,
  width,
  height,
  padding,
  fontSize,
  icon
}) => {
  return (
    <InputContainer key={name} padding={padding}>
      <Label>{label}</Label>
      <InputWrapper width={width} height={height}>
        {icon}
        <Input
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          label={label}
          error={error}
          width={width}
          height={height}
          fontSize={fontSize}
        />
      </InputWrapper>
      <Error>{error}</Error>
    </InputContainer>
  );
};

export default TextInput;
