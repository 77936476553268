import styled, { css } from 'styled-components';
import React from 'react';

const Link = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Div = styled.div`
  display: inline-block;
  height: ${props => props.height && props.height};
  width: ${props => props.width && props.width};
  font-size: 16px;
  letter-spacing: NaNpx;
  line-height: 19px;
  text-align: center;
  color: ${props => props.color ? props.color : '#FFF'};
  margin: 0px 18px;
  font-weight: ${props => props.active ? '700' : '500'};

  ${props =>
    props.bold &&
    css`
      color: ${props => props.color ? props.color : '#FFF'};
      font-family: 'Helvetica Neue';
      font-size: 16px;
      font-weight: 700;
      letter-spacing: NaNpx;
      line-height: 19px;
      text-align: center;
    `}
    
  @media (max-width: 1024px) {
   font-size: 12px;
  }
`;

const BottomNavBar = styled.div`
  width: 40%;
  height: 4px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #47d7e7, #3f98ff);
  margin-top: ${props => props.bottomPadding || '17px'};
  opacity: ${props => props.active ? 1 : 0}
  -webkit-transition: opacity 250ms ease-in;
  -moz-transition: opacity 250ms ease-in;
  -ms-transition: opacity 250ms ease-in;
  -o-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
  
  ${Link}:hover & {
    opacity: 1;
  }
`;

const NavButton = (props) => {
  return (
      <Link {...props}>
          <Div active={props.active} color={props.color}>
              {props.children}
          </Div>
          <BottomNavBar active={props.active} bottomPadding={props.bottomPadding}/>
      </Link>
  );
};

export default NavButton;