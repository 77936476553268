import styled from 'styled-components';

export const TableContainer = styled.div`
  flex: 1;
  padding: 30px 20px;
  border-radius: 16px;
  background-color: #f6faff;
`;

export const IconWrapper = styled.div`
  background-color: ${props => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  margin-right: 18px;
`;

export const FormRow = styled.form`
  display: flex;
  flex: 1 0 100%;
`;
