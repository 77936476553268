import styled from 'styled-components';

import colors from '../../../../constants/colors';

export const SubHeading = styled.h3`
  font-family: HelveticaNeue;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 0;
  margin: 0;
`;

export const SubHeadingBody = styled.h4`
  font-family: HelveticaNeue;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  margin: 0;
`;

export const SubHeadingTotal = styled.h3`
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 0;
  margin: 0;
`;

export const ChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify && props.justify};
`;

export const ChartHeaderFilter = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartHeaderContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 33px 25px;
  border-bottom: 1px solid ${colors.veryLightBlue};
  &:first-child {
    border-right: 1px solid ${colors.veryLightBlue};
  }

  &:last-child {
    border-left: 1px solid ${colors.veryLightBlue};
  }
`;
