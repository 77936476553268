import * as Yup from 'yup';

export default Yup.object().shape({
  airport: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  icao: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  area: Yup.string().required('Required'),
  contactName: Yup.string().required('Required'),
  managerName: Yup.string().required('Required'),
  mobileNumber: Yup.string().required('Required'),
  landline: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

// FBO details

// fbo logo
// airport
// fbo name
// ICAO code (could just pull straight off the airport once synced with Algolia)

// location (should be reworked, this is confusing)

// Physical address
// Metropolitan area
// City

// contact

// contact name
// mobile number
// email
// general manager name
// landline phone number
// notes
