import styled from 'styled-components';

export const Header = styled.header`
  flex: 0 0 auto;
  min-width: 100%;
  background-color: #355280; 
  background-image: linear-gradient(to right, #355280, #253f68);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
`;

export const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const TitleContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  color: #FFF;
  padding: 0 32px;
  margin 0 auto;
  
  & > h2 {
    margin: 15px 0 22px 0;
  }
  
  @media (min-width: 1200px) {
    padding: 0;
  }
`;
