import { fork, takeLatest } from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './requests.actionTypes';
import { createAsyncSaga } from '../utils';

function* getRequests(action) {
  yield fork(createAsyncSaga, action, Firebase.getRequests, {});
}

function* getRequestsSuccess(action) {
  // handle success
}

function* getRequestsFailure(action) {
  // handle failure
}

function* updateRequest(action) {
  yield console.log('Update Request Saga', action.payload);
  yield fork(createAsyncSaga, action, Firebase.updateRequest, action.payload);
}

function* updateRequestSuccess(action) {
  // handle success
}

function* updateRequestFailure(action) {
  // handle failure
}

function* updateRequestStatus(action) {
  yield console.log('Update Request Saga', action.payload);
  // yield fork(createAsyncSaga, action, Firebase.updateRequest, action.payload);
}

function* updateRequestStatusSuccess(action) {
  // handle success
}

function* updateRequestStatusFailure(action) {
  // handle failure
}

export function* requestsSaga() {
  yield takeLatest(actionTypes.GET_REQUESTS.REQUEST, getRequests);
  yield takeLatest(actionTypes.GET_REQUESTS.SUCCESS, getRequestsSuccess);
  yield takeLatest(actionTypes.GET_REQUESTS.FAILURE, getRequestsFailure);
  yield takeLatest(actionTypes.UPDATE_REQUEST.REQUEST, updateRequest);
  yield takeLatest(actionTypes.UPDATE_REQUEST.SUCCESS, updateRequestSuccess);
  yield takeLatest(actionTypes.UPDATE_REQUEST.FAILURE, updateRequestFailure);
  yield takeLatest(actionTypes.UPDATE_REQUEST.REQUEST, updateRequestStatus);
  yield takeLatest(
    actionTypes.UPDATE_REQUEST.SUCCESS,
    updateRequestStatusSuccess
  );
  yield takeLatest(
    actionTypes.UPDATE_REQUEST.FAILURE,
    updateRequestStatusFailure
  );
}
