import AircraftPricing from './AircraftPricing';
import { connect } from 'react-redux';

import { getAirplanes } from '../../../../state/airplanes/airplanes.actions';

const mapState = ({ airplanes }) => ({
  airplanes
});

export default connect(
  mapState,
  {
    getAirplanes,
  }
)(AircraftPricing);
