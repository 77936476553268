import React, { PureComponent as Component } from 'react';
import { Dialog, Pane } from 'evergreen-ui';
import { GradientButton } from '../../buttons/GradientButton';
import lightningGradient from '../../../../assets/images/lightningGradient.png';
import colors from '../../../../constants/colors';
import { Title, Text } from '../../text';

class IntroModal extends Component {
  state = {
    show: true,
  };

  static getDerivedStateFromProps({ show }, prevState) {
    if (show) {
      return { show };
    }
    return {};
  }

  render() {
    const { onModalClose, icon, title, contentText, keyFlag } = this.props;
    const { show } = this.state;
    let flag = localStorage.getItem(`${keyFlag}IntroModalFlag`);
    return (
      <Dialog
        hasClose={false}
        isShown={show && flag === null}
        onCloseComplete={onModalClose}
        hasFooter={false}
        hasHeader={false}
      >
        <Pane
          margin="20px"
          marginTop="60px"
          display="flex"
          justifyContent="center"
        >
          <Pane width="100px" display="flex" justifyContent="center">
            <img src={icon} alt="icon-graphic" />
          </Pane>
        </Pane>
        <Pane display="flex" justifyContent="center">
          <Title>{title}</Title>
        </Pane>
        <Pane
          margin="20px"
          marginTop="0"
          marginBottom="60px"
          display="flex"
          justifyContent="center"
        >
          <Text textAlign="center" fontWeight="bold">
            {contentText}
          </Text>
        </Pane>
        <Pane margin="20px" display="flex" justifyContent="center">
          <GradientButton
            bold
            height="50px"
            width="260px"
            onClick={() => {
              this.setState({ show: false }, () => {
                localStorage.setItem(
                  `${keyFlag}IntroModalFlag`,
                  JSON.stringify(true)
                );
              });
            }}
          >
            Sounds Good
          </GradientButton>
        </Pane>
      </Dialog>
    );
  }
}

export default IntroModal;
