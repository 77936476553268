import React from 'react';

import { Header, Container, ButtonsContainer } from './Header.styled';
import { GradientButton, TranslucentButton } from '../../buttons';
import { LogoWithText } from '../../logos/LogoWithText';

export default ({ push, location }) => {
  const currentLocation = location.pathname;

  const renderButtons = () => {
    if (currentLocation === '/signup' || currentLocation === '/login') {
      return (
        <ButtonsContainer>
          {currentLocation === '/signup' && (
            <div style={{ flex: '0 0 auto', paddingRight: '12px' }}>
              <TranslucentButton
                onClick={() => console.log('pressed')}
                width="178px"
                height="40px"
              >
                Request FBO Demo
              </TranslucentButton>
            </div>
          )}
          <div style={{ flex: '0 0 auto', paddingRight: '32px' }}>
            <TranslucentButton
              onClick={() =>
                push(currentLocation === '/login' ? '/signup' : '/login')
              }
              width="148px"
              height="40px"
            >
              {currentLocation === '/login' ? 'FBO Sign Up' : 'FBO Sign In'}
            </TranslucentButton>
          </div>
        </ButtonsContainer>
      );
    }
  };

  return (
    <Header>
      <Container>
        <LogoWithText />
        {renderButtons()}
      </Container>
    </Header>
  );
};
