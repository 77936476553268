import React from 'react';
import styled from 'styled-components';

import lightningGradient from '../../../../assets/images/lightningGradient.png';
import nozlLogo from '../../../../assets/images/nozlLogo.png';

const LogoContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 20px;
  padding-left: 12px;
  max-width: ${props => props.width && props.width};
  flex: 0 0 auto;
  align-self: center;
`;

const LogoWithText = () => (
  <>
    <LogoContainer width="44px">
      <img src={lightningGradient} alt="logo-graphic" />
    </LogoContainer>
    <LogoContainer width="72px">
      <img src={nozlLogo} alt="logo-text" />
    </LogoContainer>
  </>
);

export default LogoWithText;
