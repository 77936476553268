import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Page } from './components/ui/layout';
import { onAuthStateChanged } from './state/auth/auth.actions';

class App extends Component {
  componentDidMount() {
    this.props.onAuthStateChanged();
  }

  renderLoader() {
    return <div>Loading...</div>;
  }

  render() {
    const { auth } = this.props;

    return (
      <Page>
        {auth.status === 'pending' ? this.renderLoader() : this.props.children}
      </Page>
    );
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  { onAuthStateChanged }
)(App);
