import { all, fork, takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './auth.actionTypes';
import { getUser } from '../user/user.actions';
import { createAsyncSaga } from '../utils';
import { signUpSaga } from './signup/signup.saga';
import { loginSaga } from './login/login.saga';

function* logout(action) {
  yield fork(createAsyncSaga, action, Firebase.signOut, {});
}

function* logoutSuccess(action) {
  yield put(push('/login'));
}

function* onAuthStateChanged(action) {
  yield fork(createAsyncSaga, action, Firebase.onAuthStateChanged, action);
}

function* onAuthStateChangedSuccess(action) {
  yield put(getUser());
}

function* onAuthStateChangedFailure(action) {
  // yield put(push('/login'));
}

export function* authSaga() {
  yield takeLatest(actionTypes.LOG_OUT.REQUEST, logout);
  yield takeLatest(actionTypes.LOG_OUT.SUCCESS, logoutSuccess);
  yield takeLatest(
    actionTypes.ON_AUTH_STATE_CHANGED.REQUEST,
    onAuthStateChanged
  );
  yield takeLatest(
    actionTypes.ON_AUTH_STATE_CHANGED.SUCCESS,
    onAuthStateChangedSuccess
  );
  yield takeLatest(
    actionTypes.ON_AUTH_STATE_CHANGED.FAILURE,
    onAuthStateChangedFailure
  );
  yield all([fork(signUpSaga), fork(loginSaga)]);
}
