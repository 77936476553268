import styled from 'styled-components';

export const Header = styled.header`
  flex: 0 0 auto;
  height: 100px;
  min-width: 100%;
  position: relative;
  z-index: 10;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
`;

export const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
