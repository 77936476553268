import styled from 'styled-components';

export const Heading = styled.h1`
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 24px;
  padding-bottom: 24px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #f6faff;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 4px;
  padding-right: 4px;
  flex: ${props => (props.flex ? props.flex : 1)};
`;

export const ModalTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  padding-right: 4px;
  flex: ${props => (props.flex ? props.flex : 1)};
`;

export const TextH2 = styled.span`
  font-family: HelveticaNeue;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d343e;
  margin: 6px 20px;
`;

export const TextH4 = styled.span`
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #90a3bc;
  margin: 6px 20px;
`;

export const TextH6 = styled.span`
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d343e;
  margin: 6px 20px;
`;

export const EmptyText = styled.h4`
  font-family: HelveticaNeue;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d343e;
  margin-bottom: 100px;
  text-align: center;
`;
