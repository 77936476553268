import { fork, takeLatest } from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as actionTypes from './airplanes.actionTypes';
import { createAsyncSaga } from '../utils';

function* getAirplanes(action) {
  yield fork(createAsyncSaga, action, Firebase.getAirplanes, {});
}

function* getAirplanesSuccess(action) {
  // handle success
}

function* getAirplanesFailure(action) {
  // handle failure
}

export function* airplanesSaga() {
  yield takeLatest(actionTypes.GET_AIRPLANES.REQUEST, getAirplanes);
  yield takeLatest(actionTypes.GET_AIRPLANES.SUCCESS, getAirplanesSuccess);
  yield takeLatest(actionTypes.GET_AIRPLANES.FAILURE, getAirplanesFailure);
}
