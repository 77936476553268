import { ROW_TYPES } from '../../../../ui/tables/EditableTable';

export const labels = {
  smallAircraft: 'Small Aircraft',
  mediumAircraft: 'Medium Aircraft',
  largeAircraft: 'Large Aircraft',
};

export const servicesLabels = {
  hangarFee: {
    name: 'Hangar Fee',
    type: ROW_TYPES.DOLLAR,
  },
  rampFee: {
    name: 'Ramp Fee',
    type: ROW_TYPES.DOLLAR,
  },
  galToWaiveHandlingFee: {
    name: 'Gal to Waive Handling Fee',
    type: ROW_TYPES.GAL,
  },
  handlingFee: {
    name: 'Handling Fee',
    type: ROW_TYPES.DOLLAR,
  },
  deIcingPerGallon: {
    name: 'De-icing per gallon',
    type: ROW_TYPES.DOLLAR,
  },
};

export const data = [
  {
    name: 'Hangar Fee',
    type: ROW_TYPES.DOLLAR,
    values: {
      smallAircraft: 300,
      mediumAircraft: 500,
      largeAircraft: 800,
    },
  },
  {
    name: 'Ramp Fee',
    type: ROW_TYPES.DOLLAR,
    values: {
      smallAircraft: 300,
      mediumAircraft: 500,
      largeAircraft: 800,
    },
  },
  {
    name: 'Gal to Waive Handling Fee',
    type: ROW_TYPES.GAL,
    values: {
      smallAircraft: 50,
      mediumAircraft: 150,
      largeAircraft: 300,
    },
  },
  {
    name: 'Handling Fee',
    type: ROW_TYPES.DOLLAR,
    values: {
      smallAircraft: 50,
      mediumAircraft: 150,
      largeAircraft: 300,
    },
  },
  {
    name: 'De-icing per gallon',
    type: ROW_TYPES.DOLLAR,
    values: {
      smallAircraft: 50,
      mediumAircraft: 150,
      largeAircraft: 300,
    },
  },
];
