import { throttle, takeLatest, fork, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as Firebase from '../../../services/firebase';

import { createAsyncSaga } from '../../utils';
import { LOGIN_WITH_EMAIL } from '../auth.actionTypes';

function* login(action) {
  yield fork(
    createAsyncSaga,
    action,
    Firebase.loginWithEmailAndPassword,
    action.payload
  );
}

function* loginSuccess(action) {
  yield put(push('/dashboard'));
}

function* loginFailure(action) {
  // do something to show login failure
}

export function* loginSaga() {
  yield throttle(1000, LOGIN_WITH_EMAIL.REQUEST, login);
  yield takeLatest(LOGIN_WITH_EMAIL.SUCCESS, loginSuccess);
  yield takeLatest(LOGIN_WITH_EMAIL.FAILURE, loginFailure);
}
