import React from 'react';
import numeral from 'numeral';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'evergreen-ui';
import { Row, Cell } from '../../../../ui/tables';
import colors from '../../../../../constants/colors';
import { IconWrapper } from './ServicesTable.styled';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const ROW_FORMAT = (type, value) => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return numeral(value).format('$0,0.00');
    case ROW_TYPES.GAL:
      return `${value} gal`;
    default:
      return value;
  }
};

const DisplayRow = props => {
  const { row, setEditRow, deleteRow, disableEdit, disableAdd } = props;

  return (
    <Row>
      <Cell flex={0.5}>
        {disableEdit || (
          <IconWrapper bgColor={colors.blueGray} onClick={() => setEditRow()}>
            <Icon icon={faPen} color="white" />
          </IconWrapper>
        )}
      </Cell>
      <Cell flex={5}>{row.name}</Cell>
      {Object.keys(row.values).map((name, i) => (
        <Cell key={`et-${i}-${row.values[name]}`}>
          {ROW_FORMAT(row.type, row.values[name])}
        </Cell>
      ))}

      <Cell flex={0.5}>
        {disableEdit || (
          <IconButton
            appearance="minimal"
            icon="trash"
            intent="danger"
            height={36}
            onClick={() => deleteRow()}
          />
        )}
      </Cell>
    </Row>
  );
};

export default DisplayRow;
