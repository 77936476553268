import React, { useState } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { Formik } from 'formik';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'evergreen-ui';

import Table from './Table';
import Row from './Row';
import Cell from './Cell';
import { GradientButton, GradientOutlineButton } from '../buttons';
import { TextInput } from '../forms';
import colors from '../../../constants/colors';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const ROW_FORMAT = (type, value) => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return numeral(value).format('$0,0.00');
    case ROW_TYPES.GAL:
      return `${value} gal`;
    default:
      return value;
  }
};

const ROW_VALUE_TYPE = type => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return 'number';
    case ROW_TYPES.GAL:
      return 'number';
    default:
      return '';
  }
};

const feeData = (data) => {
  return {
    name: data ? data.name : 'name',
    value: data ? data.value : '0'
  };
};

const TableContainer = styled.div`
  flex: 1;
  padding: 30px 20px;
  border-radius: 16px;
  background-color: #f6faff;
`;

const IconWrapper = styled.div`
  background-color: ${props => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  margin-right: 18px;
`;

const FormRow = styled.form`
  display: flex;
  flex: 1 0 100%;
`;

const DisplayRow = props => {
  const { row, setEditRow, deleteRow } = props;
  return (
    <Row>
      <Cell flex={0.5}>
        <IconWrapper bgColor={colors.blueGray} onClick={() => setEditRow()}>
          <Icon icon={faPen} color="white" />
        </IconWrapper>
      </Cell>
      <Cell flex={5}>{row.name}</Cell>
      <Cell>
        {ROW_FORMAT(row.type, row.value)}
      </Cell>
      <Cell flex={0.5}>
        <IconButton
          appearance="minimal"
          icon="trash"
          intent="danger"
          height={36}
          onClick={() => deleteRow()}
        />
      </Cell>
      <Cell />
    </Row>
  );
};

const EditRow = props => {
  const { row, setEditRow: cancelEdit, onEditSave } = props;
  return (
    <Formik
      initialValues={{
        name: row.name,
        value: row.value
      }}
      isInitialValid
      // validationSchema={}
      onSubmit={values => {
        onEditSave(values);
        cancelEdit();
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
        isValid,
      }) => (
        <FormRow onSubmit={handleSubmit}>
          <Cell flex={0.5}>
            <IconWrapper bgColor={colors.blueGray} onClick={() => cancelEdit()}>
              <Icon icon={faTimes} color="white" />
            </IconWrapper>
          </Cell>
          <Cell flex={5}>
            <TextInput
              onChange={handleChange}
              onBlur={() => setFieldTouched(row.name)}
              name="name"
              value={values.name}
              error={touched.name && errors.name}
              label="Field Name"
              width="100%"
              padding="0px"
            />
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <GradientOutlineButton
                bold
                height="40px"
                width="150px"
                type="cancel"
                onClick={() => cancelEdit()}
                color={colors.brightLightBlue}
              >
                Cancel
              </GradientOutlineButton>
              <div style={{ width: '20px' }} />
              <GradientButton
                bold
                height="40px"
                width="150px"
                type="submit"
                disabled={!isValid}
              >
                Save Changes
              </GradientButton>
            </div>
          </Cell>
          <Cell>
            <TextInput
              onChange={handleChange}
              onBlur={() => setFieldTouched(row.value)}
              type={ROW_VALUE_TYPE(row.type)}
              name="value"
              value={values.value}
              error={touched.value && errors.value}
              label="Field Value"
              width="100%"
              padding="0px"
            />
          </Cell>
        </FormRow>
      )}
    </Formik>
  );
};

const AddRow = props => {
  const { onSelect } = props;
  return (
    <Row>
      <Cell flex={0.5}>
        <IconButton
          appearance="minimal"
          icon="plus"
          height={36}
          onClick={onSelect}
        />
      </Cell>
      <Cell />
    </Row>
  );
};

const FeesTable = props => {
  const { data, onSave } = props;
  const [editRow, setEditRow] = useState(-1);
  const [fees, setFees] = useState(data || []);
  return (
    <TableContainer>
      <Table>
        {fees.map((row, k) =>
          editRow === k ? (
            <EditRow
              row={row}
              key={`et-${k}`}
              setEditRow={() => setEditRow(-1)}
              onEditSave={values => onSave({ row, values })}
            />
          ) : (
            <DisplayRow
              row={row}
              setEditRow={() => setEditRow(k)}
              deleteRow={() => setFees(fees.filter((_, i) => i !== k))}
              key={`et-${k}`}
            />
          )
        )}
        <AddRow
          onSelect={() => setFees([...fees, feeData()])}
        />
      </Table>
    </TableContainer>
  );
};

export default FeesTable;
