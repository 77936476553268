import React from 'react';
import styled from 'styled-components';

const Cell = styled.div`
  flex: ${props => (props.flex ? props.flex : '1 1 auto')};
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 15px 8px;
  ${props => (props.unbordered ? '' : 'width: 50px')};
  ${props =>
    props.unbordered ? '' : 'border-bottom: 1px solid rgba(0, 34, 87, 0.08)'};
  display: flex;
  align-items: center;
`;

const Container = styled.span`
  flex: ${props => (props.flex ? props.flex : '1 1 auto')};
  align-self: center;
`;

export default props => (
  <Cell {...props}>
    <Container>{props.children}</Container>
  </Cell>
);
