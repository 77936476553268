import styled from 'styled-components';

export default styled.h2`
  height: 19px;
  width: 91px;
  color: #2d343e;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
`;
