import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from '../auth/auth.reducer';
import userReducer from '../user/user.reducer';
import requestsReducer from '../requests/requests.reducer';
import feedbackReducer from '../feedback/feedback.reducer';
import stripeReducer from '../stripe/stripe.reducer';
import airplanesReducer from '../airplanes/airplanes.reducer';
import servicesReducer from '../services/services.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    user: userReducer,
    requests: requestsReducer,
    feedback: feedbackReducer,
    stripe: stripeReducer,
    airplanes: airplanesReducer,
    services: servicesReducer,
  });
