import { createAsyncActionTypes } from '../utils';

export const ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE';

export const ON_AUTH_STATE_CHANGED = createAsyncActionTypes(
  'ON_AUTH_STATE_CHANGED'
);

export const LOGIN_WITH_EMAIL = createAsyncActionTypes('LOGIN_WITH_EMAIL');
export const SIGN_UP_WITH_EMAIL = createAsyncActionTypes('SIGN_UP_WITH_EMAIL');

export const LOG_OUT = createAsyncActionTypes('LOG_OUT');
export const FORGOT_PASSWORD = createAsyncActionTypes('FORGOT_PASSWORD');
