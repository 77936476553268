import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Feedback from './Feedback';
import { logout } from '../../../state/auth/auth.actions';
import { getUser } from '../../../state/user/user.actions';
import { getFeedback } from '../../../state/feedback/feedback.actions';

const mapState = ({ auth, user, feedback }) => ({
  auth,
  user,
  feedback,
});

export default connect(
  mapState,
  { getUser, getFeedback, push, logout }
)(Feedback);
