import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  display: inline-flex;
  flex: 0 0 auto;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #002257;
  margin-top: 32px;
  
  & > * {
    margin: 0 7px;
  }
  
  a {
    color: #4dcceb;
    font-weight: bold; 
  }
`;

const Footer = props => {
  const footer = props.children
      ? <StyledFooter>{props.children}</StyledFooter>
      : <StyledFooter>
          <span>© 2018 NOZL.com. All rights reserved.</span>
          <a href="/terms" >Terms & Conditions</a>
          <a href="/privacy">Privacy Policy</a>
      </StyledFooter>;
  return footer;
};

export default Footer;
