import React, { Component } from 'react';
import { Formik } from 'formik';

import { FancyPage, ContentContainer } from '../../ui/layout';
import { Form, TextInput } from '../../ui/forms';
import { Title, SubText } from '../../ui/text';
import { GradientButton } from '../../ui/buttons';
import LoginSchema from '../../../utils/forms/schema.login';

class Login extends Component {
  handleSubmit = values => {
    this.props.login(values);
  };

  render() {
    return (
      <FancyPage>
          <ContentContainer
              borderRadius="9px 9px 0 0"
          >
            <div style={{ maxWidth: '460px', position: 'relative', left: '-20px' }}>
              <Title style={{ paddingLeft: '20px', paddingTop: '60px' }}>
                Sign in as FBO
              </Title>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                isInitialValid={false}
                validationSchema={LoginSchema}
                onSubmit={values => this.handleSubmit(values)}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  isValid,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('email')}
                      type="text"
                      name="email"
                      value={values.email}
                      error={touched.email && errors.email}
                      label="Email"
                    />
                    <TextInput
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('password')}
                      type="password"
                      name="password"
                      value={values.password}
                      error={touched.password && errors.password}
                      label="Password"
                    />
                    <div style={{ padding: '20px' }}>
                      <GradientButton
                        bold
                        height="50px"
                        width="455px"
                        type="submit"
                        disabled={!isValid}
                      >
                        Sign In
                      </GradientButton>
                    </div>
                  </Form>
                )}
              </Formik>
              <div style={{ paddingLeft: '30px', width: '100%' }}>
                <SubText>Forgot your password? Reset Password</SubText>
              </div>
            </div>
          </ContentContainer>
      </FancyPage>
    );
  }
}

export default Login;
