import React from 'react';

import {
    FancyPage,
    ContentContainer,
} from '../../ui/layout';
import { SectionHeading, Text } from '../../ui/text';
import colors from '../../../constants/colors';
import checkGradient from "../../../assets/images/checkGradient.png";

const Approval = () => {
  return (
      <FancyPage>
          <ContentContainer
              maxWidth="800px"
              padding="0 30px"
              flexDirection="column"
              alignItems="center"
              borderRadius="9px 9px 0 0"
          >
              <img src={checkGradient} style={{ width: 'initial' }} width="120px" height="120px" alt="logo-graphic" />
              <SectionHeading textAlign="center">Your FBO Profile Has Been Submitted For Approval!</SectionHeading>
              <Text textAlign="center">Thank you for your request to become an FBO on NOZL. We will be in touch with you shortly. </Text>
          </ContentContainer>
      </FancyPage>
  );
};

export default Approval;
