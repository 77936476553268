import React from 'react';
import styled from 'styled-components';

import {
    Header,
    Main,
    Page
} from '../../../ui/layout';
import colors from "../../../../constants/colors";
import topLeftGradient from "../../../../assets/images/group-3.png";
import bottomRightGradient from "../../../../assets/images/group-4.png";

const AspectRatioBox = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: ${props => `${(props.height / props.width) * 100}%`};
  position: relative;
  background-image: ${props => props.backgroundImage && `url(${props.backgroundImage})`};
  background-size: cover;
`;

const AspectRatioBoxInside = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
`;

const BottomAlignedContentContainer = (props) => {
    return (
        <Page
            backgroundColor={colors.darkSlateBlue}
            backgroundImage={'linear-gradient(to right, #355280, #253f68)'}
        >
            <div
                style={{
                    position: 'absolute',
                    width: '28.8%'
                }}
            >
                <AspectRatioBox
                    width={412}
                    height={335}
                    backgroundImage={topLeftGradient}
                >
                    <AspectRatioBoxInside />
                </AspectRatioBox>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: '79.9%'
                }}
            >
                <AspectRatioBox
                    width={1150}
                    height={612}
                    backgroundImage={bottomRightGradient}
                >
                    <AspectRatioBoxInside />
                </AspectRatioBox>
            </div>
            <Header/>
            <Main
                justifyContent="flex-end"
                style={{
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                {props.children}
            </Main>
        </Page>
    );
};

export default BottomAlignedContentContainer;
