import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Dashboard from './Dashboard';
import { logout } from '../../../state/auth/auth.actions';
import { getUser } from '../../../state/user/user.actions';
import { getAirplanes } from '../../../state/airplanes/airplanes.actions';
import { getRequests } from '../../../state/requests/requests.actions';
import { getFeedback } from '../../../state/feedback/feedback.actions';
import { testServices } from '../../../state/services/services.actions';

const mapState = ({ auth, user, requests, feedback, services }) => ({
  auth,
  user,
  requests,
  feedback,
  services,
});

export default connect(
  mapState,
  {
    getUser,
    getAirplanes,
    getRequests,
    getFeedback,
    push,
    logout,
    testServices,
  }
)(Dashboard);
