import firebase from 'firebase';

import CollectionManage from './collection';

export const Feedback = new CollectionManage('feedback');

export const getFeedback = () => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    return Feedback.search('fboId', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents found.');
          return;
        }

        const data = snapshot.docs.map(doc => {
          if (doc.exists) {
            return { ...doc.data(), uid: doc.id };
          }
        });

        return data;
      })
      .catch(err => {
        console.log('get requests error', err);
        throw err;
      });
  }

  return Promise.reject('You are not signed in.');
};
