import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import starGradient from '../../../assets/images/starGradient.png';
import starGrey from '../../../assets/images/starGrey.png';

import colors from '../../../constants/colors';

const RatingContainer = styled.div`
  flex: 1;
`;

const StarGradient = styled.img.attrs({
  src: starGradient,
})`
  width: 20px;
  height: 20px;
  margin: 2px;

  @media (max-width: 1024px) {
    width: 10px;
    height: 10px;
  }
`;

const StarGrey = styled.img.attrs({
  src: starGrey,
})`
  width: 20px;
  height: 20px;
  margin: 2px;

  @media (max-width: 1024px) {
    width: 10px;
    height: 10px;
  }
`;

const getStarIcons = (start, end, value) => {
  const tmp = [];
  for (let i = start; i < end; i++) {
    let icon = <StarGradient key={`star${i}`} />;
    if (value <= i) {
      icon = <StarGrey key={`star${i}`} />;
    }
    tmp.push(icon);
  }
  return tmp;
};

const Rating = ({
  max = 5,
  initialRating = 5,
  currentRating = 5,
  onChange,
}) => {
  return (
    <RatingContainer>{getStarIcons(0, max, currentRating)}</RatingContainer>
  );
};

export default Rating;
