import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Services from './Services';
import { logout } from '../../../state/auth/auth.actions';
import { getUser, updateUser } from '../../../state/user/user.actions';

const mapState = ({ auth, user }) => ({
  auth,
  user,
});

export default connect(
  mapState,
  { updateUser, getUser, push, logout }
)(Services);
