import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const OnboardingRoute = ({
  component: Component,
  componentProps,
  auth,
  user,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.status === 'success') {
          return user.status === 'success' && user.isApproved ? (
            <Redirect to="/dashboard" />
          ) : (
            <Component {...{ ...props, ...componentProps }} />
          );
        } else {
          return <Redirect to="/signup" />;
        }
      }}
    />
  );
};

export default connect(
  ({ auth, user }) => ({ auth, user }),
  {}
)(OnboardingRoute);
