import styled from 'styled-components';

export default styled.div`
  flex: ${props => (props.flex ? props.flex : '1 1 auto')};
  font-family: HelveticaNeue;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 15px 8px;
  padding-bottom: 20px;
  width: 50px;
`;
