import styled from 'styled-components';

export const IconWrapper = styled.div`
  background-color: ${props => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  margin-right: 18px;
`;

export const Heading = styled.h1`
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 24px 0;
`;

export const SubHeading = styled.h2`
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 24px 0 11px 0;
`;

export const TierBox = styled.div`
  flex: 1;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  border-color: ${props => props.borderColor && props.borderColor};
  min-height: 155px;
  padding: 24px;
  display: flex;
  
  &:last-child {
    border-right: 0;
  }
`;

export const TierHeader = styled.p`
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  margin: 0;
  padding: 0;
  padding-bottom: 12px;
  padding-top: 8px;
`;

export const GallonText = styled.h3`
  font-family: HelveticaNeue;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  margin: 0;
  padding-bottom: 12px;
`;

export const GallonSubtext = styled.h4`
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  margin: 0;
  padding-bottom: 20px;
`;
