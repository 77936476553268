import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Signup from './Signup';
import { signUp } from '../../../state/auth/auth.actions';

const mapState = ({ auth }) => ({ auth });

export default connect(
  mapState,
  { signUp, push }
)(Signup);
