import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../auth/auth.saga';
import { userSaga } from '../user/user.saga';
import { requestsSaga } from '../requests/requests.saga';
import { feedbackSaga } from '../feedback/feedback.saga';
import { stripeSaga } from '../stripe/stripe.saga';
import { airplanesSaga } from '../airplanes/airplanes.saga';
import { servicesSaga } from '../services/services.saga';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(requestsSaga),
    fork(feedbackSaga),
    fork(stripeSaga),
    fork(airplanesSaga),
    fork(servicesSaga),
  ]);
}
