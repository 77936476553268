import { firebaseApp } from '../../config/firebase';
import CollectionManager from './collection';

export const Users = new CollectionManager('fbos');

/**
 * get current signed in User
 */
export const getProfile = () => {
  const currentUser = firebaseApp.auth().currentUser;
  if (currentUser) {
    return Users.getDoc(currentUser.uid)
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          return docSnapshot.data();
        }
        return Promise.reject({ message: 'You did not log in.' });
      })
      .then(data => {
        return {
          id: currentUser.uid,
          ...currentUser.toJSON(),
          ...data,
        };
      });
  }
  return Promise.reject('You are not signed in.');
};

/**
 * Get Current User
 */
export const getUser = id => Users.getDoc(id);

/**
 * Update user Email
 */
export const updateEmail = email => {
  const currentUser = firebaseApp.auth().currentUser;
  if (currentUser) {
    return currentUser.updateEmail(email);
  }
  return Promise.reject('You are not signed in.');
};

/**
 * Update user Password
 */
export const updatePassword = password => {
  const currentUser = firebaseApp.auth().currentUser;
  if (currentUser) {
    return currentUser.updatePassword(password);
  }
  return Promise.reject('You do not have signed in.');
};

/**
 * Update user firebase profile
 */
export const updateFirebaseProfile = profile => {
  const currentUser = firebaseApp.auth().currentUser;
  if (currentUser) {
    return currentUser.updateProfile(profile);
  }
  return Promise.reject('You do not have signed in.');
};

/**
 * Update user
 */
export const updateUser = data => {
  const currentUser = firebaseApp.auth().currentUser;
  if (currentUser) {
    return Users.updateDoc(currentUser.uid, data);
  }
  return Promise.reject('You are not signed in.');
};
