import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import _Header from './Header';

export const Header = connect(
  null,
  { push }
)(withRouter(_Header));
