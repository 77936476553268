import React from 'react';
import numeral from 'numeral';
import { Formik } from 'formik';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Cell } from '../../../../ui/tables';
import { GradientButton, GradientOutlineButton } from '../../../../ui/buttons';
import { TextInput } from '../../../../ui/forms';
import colors from '../../../../../constants/colors';
import { IconWrapper, FormRow } from './ServicesTable.styled';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const ROW_FORMAT = (type, value) => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return numeral(value).format('$0,0.00');
    case ROW_TYPES.GAL:
      return `${value} gal`;
    default:
      return value;
  }
};

const ROW_VALUE_TYPE = type => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return 'number';
    case ROW_TYPES.GAL:
      return 'number';
    default:
      return '';
  }
};

const EditRow = props => {
  const { row, setEditRow: cancelEdit, onEditSave } = props;

  return (
    <Formik
      initialValues={row.values}
      isInitialValid
      // validationSchema={}
      onSubmit={values => {
        onEditSave(values);
        cancelEdit();
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
        isValid,
      }) => (
        <FormRow onSubmit={handleSubmit}>
          <Cell flex={0.5}>
            <IconWrapper bgColor={colors.blueGray} onClick={() => cancelEdit()}>
              <Icon icon={faTimes} color="white" />
            </IconWrapper>
          </Cell>
          <Cell flex={5}>
            {row.name}
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <GradientOutlineButton
                bold
                height="40px"
                width="150px"
                type="cancel"
                onClick={() => cancelEdit()}
                color={colors.brightLightBlue}
              >
                Cancel
              </GradientOutlineButton>
              <div style={{ width: '20px' }} />
              <GradientButton
                bold
                height="40px"
                width="150px"
                type="submit"
                disabled={!isValid}
              >
                Save Changes
              </GradientButton>
            </div>
          </Cell>
          {Object.keys(row.values).map((name, i) => (
            <Cell key={`et-${i}-${row.values[name]}`}>
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched(name)}
                type={ROW_VALUE_TYPE(row.type)}
                name={name}
                value={values[name]}
                error={touched[name] && errors[name]}
                label={ROW_FORMAT(row.type, values[name])}
                width="100%"
                padding="0px"
              />
            </Cell>
          ))}
        </FormRow>
      )}
    </Formik>
  );
};

export default EditRow;
