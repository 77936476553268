import React from 'react';
import numeral from 'numeral';

import { Cell, CellHeader, DataTable } from '../../../ui/tables';
import colors from '../../../../constants/colors';
import { HighlightedText } from '../../../ui/text';

const RequestsList = ({ requests, handleRequestClick }) => {
  const columns = [
    {
      Header: () => {
        return <CellHeader color={colors.blueGray}>Registry</CellHeader>;
      },
      accessor: 'registry',
      Cell: props => (
        <Cell unbordered color={colors.charcoalGray}>
          {props.value}
        </Cell>
      ),
    },
    {
      Header: props => (
        <CellHeader color={colors.blueGray}>Operator</CellHeader>
      ),
      accessor: 'operator',
      Cell: props => (
        <Cell unbordered color={colors.charcoalGray}>
          {props.value}
        </Cell>
      ),
    },
    {
      Header: props => <CellHeader color={colors.blueGray}>Name</CellHeader>,
      accessor: 'name',
      Cell: props => (
        <Cell unbordered color={colors.charcoalGray}>
          {props.value}
        </Cell>
      ),
    },
    {
      Header: props => (
        <CellHeader color={colors.blueGray}>Date of Uplift</CellHeader>
      ),
      accessor: 'date',
      Cell: props => (
        <Cell unbordered color={colors.charcoalGray}>
          {props.value}
        </Cell>
      ),
    },
    {
      Header: props => (
        <CellHeader color={colors.blueGray}>Services</CellHeader>
      ),
      accessor: 'servicesTotal',
      Cell: props => (
        <Cell unbordered color={colors.charcoalGray}>
          {props.value}
        </Cell>
      ),
      minWidth: 300,
    },
    {
      Header: props => (
        <CellHeader color={colors.blueGray}>Total Price</CellHeader>
      ),
      accessor: 'price',
      Cell: props => (
        <Cell unbordered color={colors.charcoalGray}>
          {numeral(props.value).format('$0,0.00')}
        </Cell>
      ),
    },
    {
      Header: props => <CellHeader color={colors.blueGray}>Status</CellHeader>,
      accessor: 'status',
      Cell: props => {
        const { index } = props;
        return (
          <Cell unbordered color={colors.charcoalGray}>
            <HighlightedText
              type={props.value === 'pending' && 'warn'}
              onClick={() => handleRequestClick(requests, index)}
            >
              {props.value}
            </HighlightedText>
          </Cell>
        );
      },
    },
  ];

  return (
    <DataTable
      data={requests}
      columns={columns}
      showPagination={false}
      showPageSizeOptions={false}
      defaultPageSize={requests.length}
      defaultSortDesc={true}
      defaultSorted={[
        {
          id: 'status',
          desc: true,
        },
      ]}
    />
  );
};

export default RequestsList;
