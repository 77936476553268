import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import OnboardingRoute from './OnboardingRoute';

import Dashboard from '../components/views/Dashboard';
import Feedback from '../components/views/Feedback';
import Login from '../components/views/Login';
import Profile from '../components/views/Profile';
import Requests from '../components/views/Requests';
import Services from '../components/views/Services';
import Signup from '../components/views/Signup';
import Approval from '../components/views/Approval';
import CreateProfile from '../components/views/CreateProfile';
import PrivacyPolicy from '../components/views/PrivacyPolicy';
import TermsConditions from '../components/views/TermsConditions';
import { NewPassword } from '../components/views/NewPassword';
import { ResetPassword } from '../components/views/ResetPassword';
import { NotFound } from '../components/views/NotFound';

export default (
  <Switch>
    <PrivateRoute
      exact
      path="/"
      componentProps={{ to: '/dashboard' }}
      component={Redirect}
    />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/terms" component={TermsConditions} />
    <Route exact path="/privacy" component={PrivacyPolicy} />
    <OnboardingRoute exact path="/create-profile" component={CreateProfile} />
    <OnboardingRoute exact path="/approval" component={Approval} />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />
    <PrivateRoute exact path="/feedback" component={Feedback} />
    <PrivateRoute exact path="/new-password" component={NewPassword} />
    <PrivateRoute exact path="/profile" component={Profile} />
    <PrivateRoute exact path="/requests" component={Requests} />
    <PrivateRoute exact path="/reset-password" component={ResetPassword} />
    <PrivateRoute exact path="/services" component={Services} />
    <Route component={NotFound} />
  </Switch>
);
