import styled from 'styled-components';

export default styled.h1`
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color && props.color};
  padding: 12px;
  padding-bottom: 24px;
`;
