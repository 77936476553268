import React, { useState } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { Formik } from 'formik';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import Table from './Table';
import Row from './Row';
import Cell from './Cell';
import CellHeader from './CellHeader';
import { GradientButton, GradientOutlineButton } from '../buttons';
import { TextInput } from '../forms';
import colors from '../../../constants/colors';

export const ROW_TYPES = {
  DOLLAR: 'DOLLAR',
  GAL: 'GAL',
};

const ROW_FORMAT = (type, value) => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return numeral(value).format('$0,0.00');
    case ROW_TYPES.GAL:
      return `${value} gal`;
    default:
      return value;
  }
};

const ROW_VALUE_TYPE = type => {
  switch (type) {
    case ROW_TYPES.DOLLAR:
      return 'number';
    case ROW_TYPES.GAL:
      return 'number';
    default:
      return '';
  }
};

const TableContainer = styled.div`
  flex: 1;
  padding: 30px 20px;
  border-radius: 16px;
  background-color: #f6faff;
`;

const IconWrapper = styled.div`
  background-color: ${props => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  margin-right: 18px;
`;

const FormRow = styled.form`
  display: flex;
  flex: 1 0 100%;
`;

const DisplayRow = props => {
  const { row, setEditRow } = props;
  return (
    <Row>
      <Cell flex={0.5}>
        <IconWrapper bgColor={colors.blueGray} onClick={() => setEditRow()}>
          <Icon icon={faPen} color="white" />
        </IconWrapper>
      </Cell>
      <Cell flex={5}><span>{row.name}</span></Cell>
      {Object.keys(row.values).map((name, i) => (
        <Cell key={`et-${i}-${row.values[name]}`}>
          {ROW_FORMAT(row.type, row.values[name])}
        </Cell>
      ))}
    </Row>
  );
};

const EditRow = props => {
  const { row, setEditRow: cancelEdit, onEditSave } = props;
  return (
    <Formik
      initialValues={row.values}
      isInitialValid
      // validationSchema={}
      onSubmit={values => {
        onEditSave(values);
        cancelEdit();
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
        isValid,
      }) => (
        <FormRow onSubmit={handleSubmit}>
          <Cell flex={0.5}>
            <IconWrapper bgColor={colors.blueGray} onClick={() => cancelEdit()}>
              <Icon icon={faTimes} color="white" />
            </IconWrapper>
          </Cell>
          <Cell flex={5}>
            {row.name}
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <GradientOutlineButton
                bold
                height="40px"
                width="150px"
                type="cancel"
                onClick={() => cancelEdit()}
                color={colors.brightLightBlue}
              >
                Cancel
              </GradientOutlineButton>
              <div style={{ width: '20px' }} />
              <GradientButton
                bold
                height="40px"
                width="150px"
                type="submit"
                disabled={!isValid}
              >
                Save Changes
              </GradientButton>
            </div>
          </Cell>
          {Object.keys(row.values).map((name, i) => (
            <Cell key={`et-${i}-${row.values[name]}`}>
              <TextInput
                onChange={handleChange}
                onBlur={() => setFieldTouched(name)}
                type={ROW_VALUE_TYPE(row.type)}
                name={name}
                value={values[name]}
                error={touched[name] && errors[name]}
                label={ROW_FORMAT(row.type, values[name])}
                width="100%"
                padding="0px"
              />
            </Cell>
          ))}
        </FormRow>
      )}
    </Formik>
  );
};

const EditableTable = props => {
  const { data: test, labels, onSave, style } = props;
  const [editRow, setEditRow] = useState(-1);

  const data = test.map(service => {
    const values = Object.keys(service.values)
      .filter(key => key !== 'name')
      .reduce((obj, key) => {
        obj[key] = service.values[key];
        return obj;
      }, {});

    return { ...service, values };
  });

  const headers = [
    ...new Set(
      data
        .map(field => Object.keys(field.values))
        .flat()
        .filter(field => field !== 'name')
    ),
  ];

  return (
    <TableContainer>
      <Table>
        <Row>
          <CellHeader flex={0.5} />
          <CellHeader flex={5} />
          {headers.map((header, k) => (
            <CellHeader key={`et-h-${k}`}>
              {labels[header] || header}
            </CellHeader>
          ))}
        </Row>
        {data.map((row, k) =>
          editRow === k ? (
            <EditRow
              row={row}
              key={`et-${k}`}
              setEditRow={() => setEditRow(-1)}
              onEditSave={values => onSave({ row, values })}
            />
          ) : (
            <DisplayRow
              row={row}
              setEditRow={() => setEditRow(k)}
              key={`et-${k}`}
            />
          )
        )}
      </Table>
    </TableContainer>
  );
};

export default EditableTable;
