import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import CreateProfile from './CreateProfile';
import { updateUser } from '../../../state/user/user.actions';

const mapState = ({ auth, user }) => ({ auth, user });

export default connect(
  mapState,
  { updateUser, push }
)(CreateProfile);
