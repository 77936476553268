import axios from 'axios';

import { cloudFunctions } from '../config/firebase';
// import stripeConfig from '../config/stripe';

const api = axios.create({
  baseURL: cloudFunctions.stripeApi,
  timeout: 7000,
});

export async function chargeCustomer(data) {
  // return console.log('chargeCustomer data', data);
  try {
    const response = await api.post('/user/charge', {
      amount: data.amount,
      id: data.id,
      description: data.description,
    });

    return response.data;
  } catch (err) {
    console.log('stripe charge customer error', err);
  }
}

export default {
  chargeCustomer,
};
