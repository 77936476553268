import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import colors from '../../../../constants/colors';
import { ContentContainer } from '../../../ui/layout';
import { Table, Row, Cell, CellHeader, Heading } from '../../../ui/tables';
import { Rating } from '../../../ui/rating';
import { GradientButton } from '../../../ui/buttons';

const Feedback = ({ feedback }) => {
  const renderFeedbackData = feedbacks => {
    if (feedbacks) {
      return feedbacks.slice(0, 3).map(feedback => {
        const {
          comments,
          created_at,
          rating,
          uid,
          user = {
            registry: 'TEST12',
            operator: 'Avalair',
            name: 'John Smith',
          },
        } = feedback;

        const { registry, operator, name } = user;

        const date = moment(created_at.toDate()).format('YYYY-MM-DD');

        return (
          <Row key={uid}>
            <Cell color={colors.charcoalGray}>{registry}</Cell>
            <Cell color={colors.charcoalGray}>{operator}</Cell>
            <Cell color={colors.charcoalGray}>{name}</Cell>
            <Cell color={colors.charcoalGray}>{date}</Cell>
            <Cell color={colors.charcoalGray}>
              <Rating currentRating={rating} />
            </Cell>
            <Cell flex={5} color={colors.charcoalGray}>
              {comments}
            </Cell>
          </Row>
        );
      });
    }
  };

  const renderFeedback = feedback => {
    return (
      <Table>
        <Row>
          <CellHeader color={colors.blueGray}>Registry</CellHeader>
          <CellHeader color={colors.blueGray}>Operator</CellHeader>
          <CellHeader color={colors.blueGray}>Name</CellHeader>
          <CellHeader color={colors.blueGray}>Date</CellHeader>
          <CellHeader color={colors.blueGray}>Rating</CellHeader>
          <CellHeader flex={5} color={colors.blueGray}>
            Feedback
          </CellHeader>
        </Row>
        {renderFeedbackData(feedback)}
      </Table>
    );
  };

  return (
    <ContentContainer maxWidth="1200px"  margin="32px auto" flex="1 0 auto">
      <div style={{ width: '100%' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Heading color={colors.charcoalGrey}>Feedback</Heading>
          <Link to='/feedback'>
            <GradientButton
              style={{ padding: '10px 20px' }}
            >
              View All Feedback
            </GradientButton>
          </Link>
        </div>
        {feedback.status === 'success' && renderFeedback(feedback.feedback)}
      </div>
    </ContentContainer>
  );
};

export default Feedback;
