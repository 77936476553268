import firebase from 'firebase';

import CollectionManage from './collection';

export const Requests = new CollectionManage('requests');

export const getRequests = () => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    return Requests.search('fbo.uid', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents found.');
          return;
        }

        const data = snapshot.docs.map(doc => {
          if (doc.exists) {
            return { ...doc.data(), uid: doc.id };
          }
        });

        return data;
      })
      .catch(err => {
        console.log('get requests error', err);
        throw err;
      });
  }

  return Promise.reject('You are not signed in.');
};

export const updateRequestStatus = (requestId, status) => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    return Requests.updateDoc(requestId, { status });
  }
  return Promise.reject('You are not signed in.');
};

export const updateRequest = ({ requestId, services, total = 0 }) => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    return Requests.updateDoc(requestId, { services, total });
  }
  return Promise.reject('You are not signed in.');
};
