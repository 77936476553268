import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import MainHeader from './MainHeader';
import { logout } from '../../../../state/auth/auth.actions';

export default connect(
  ({ user, router }) => ({
    name: user.fbo.name,
    location: router.location.pathname,
  }),
  {
    push,
    logout
  }
)(MainHeader);
